<template>
    <v-col cols="12 px-1" class="pb-1">
        <div class="curtain">
            <div class="curtain__wrapper">
                <input type="checkbox"
                disabled="disabled" 
                    :checked="(passClientRandom.is_open == false || passClientRandom.is_open == true) && !passClientResult">
                <div class="curtain__panel curtain__panel--left">
                </div>
                <div class="curtain__prize">
                    <div class="d-result-style">
                        <img src="../../../../../../public/images/newYuki/Rectangle.png" class="rotate backdrop-result" />
                        <img v-if="passClientResult" class="result-img-blink d-result-animal" :src="findImageOfEachAnimal(passClientResult.between_year.year_number)
                            " :lazy-src="findImageOfEachAnimal(passClientResult.between_year.year_number)" />
                        <img v-if="passRandomNumber && !passClientResult" class=" d-result-animal"
                            :src="findImageOfEachAnimal(passRandomNumber)"
                            :lazy-src="findImageOfEachAnimal(passRandomNumber)" />
                        <img src="../../../../../../public/images/newYuki/curtain_open.png" class="d-result-curtain-open" />
                    </div>
                </div>
                <div class="curtain__panel curtain__panel--right">
                </div>
            </div>
        </div>
    </v-col>
</template>
  
  
<script>
import { mapGetters } from "vuex";
import Mixin from "../../../../../mixin/index.js";
export default {
    components: {
        // Tabs,
    },
    props: [
        "passClientTime", "passClientResult", "passClientRandom", "passRandomNumber"
    ],
    data() {
        return {
            mixins: [Mixin],
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
        ...mapGetters("$_yuki160s", {
            currentGame: "getCurrentGame",
            getLimit: "getLimit"
        }),
    },
    methods: {
    }
};
</script>