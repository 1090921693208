<template>
    <div class="plyr-row mx-auto">
      <v-row class="mx-0 my-0">
        <div class="plyr-col-1 mx-0">
          <div class="video-section">
              <TabChannelGame
                :passSelectedChannel="passSelectedChannel"
                :passChannelStatus="passChannelStatus"
                :passStartIndex="0"
                :passEndIndex="6"
                v-on:sentSetChannel="_switchChannel"
              />
              <VideoLiveForDesktop
                :channel="passChannel"
                :passSelectedChannel="passSelectedChannel"
              />
            <ResultVideoDistribution 
              :passSelectedChannel="passSelectedChannel"
              :passChannel="passChannel"
            />
              <TabChannelGame
                v-if="userInfo.home_channel && userInfo.home_channel.length >= 7"
                :passSelectedChannel="passSelectedChannel"
                :passChannelStatus="passChannelStatus"
                :passStartIndex="7"
                :passEndIndex="14"
                v-on:sentSetChannel="_switchChannel"
            />
            <TabChannelGame
              v-if="userInfo.home_channel && userInfo.home_channel.length >= 15"
              :passSelectedChannel="passSelectedChannel"
              :passChannelStatus="passChannelStatus"
              :passStartIndex="7"
              :passEndIndex="14"
              v-on:sentSetChannel="_switchChannel"
            />
          </div>
         
          <TableResult
            :passSelectedChannel="passSelectedChannel"
          />
        </div>
        <div class="plyr-col-2 mx-0">
          <BettingComponent
            :passCurrentBetting="passCurrentBetting"
            :passChannel="passChannel"
            :passGetButton="passGetButton"
            :passBettingTypeMaster="passBettingTypeMaster"
            :passWeight="passWeight"
            v-on:sentSubmitBetting="receiveSubmitBetting"
            :passSelectedChannel="passSelectedChannel"
            :passUserCurrency="passUserCurrency"
          />
          <ReportBetting
            :passSelectedChannel="passSelectedChannel"
            :passLoadingTodayReport="passLoadingTodayReport"
          />
        </div>
      </v-row>
    </div>
  </template>
  <script>
  import "@/assets/css/video-js.css";
  import "@/assets/css/homepage.css";
  import { mapGetters } from "vuex";
  import VideoLiveForDesktop from "../videoLiveForDesktop.vue";
  import TableResult from "../TableResults";
  import BettingComponent from "./BettingComponent.vue";
  import ReportBetting from "../ReportBetting.vue";
  import ResultVideoDistribution from "./ResultVideoDistribution.vue";
  import TabChannelGame from "./TabChannelGame.vue";
  export default {
    components: {
      TabChannelGame,
      VideoLiveForDesktop,
      TableResult,
      BettingComponent,
      ReportBetting,
  
      ResultVideoDistribution
    },
    props: [
      "passChannel",
      "passChannelStatus",
      "passGetButton",
      "passBettingTypeMaster",
      "passWeight",
      "passLoadingTodayReport",
      "passSelectedChannel",
      "passCurrentBetting",
      "passUserCurrency",
    ],
    data() {
      return {
          // DEFINE VARIABLE HERE
      };
    },
    computed: {
      ...mapGetters("$_modules", {
        userInfo: "getUserInfo",
      }),
    },
    methods: {
      receiveSubmitBetting(recieveBettingData) {
        this.$emit("sentSubmitBetting", recieveBettingData);
      },
      _switchChannel(selectedChannel) {
        this.$emit("sentSetChannel", selectedChannel);
      },
    },
  };
  </script>