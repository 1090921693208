const getDarkMode = (state) => state.darkMode;
const getUserInfo = (state) => state.userInfo;
const getChannelId = (state) => state.channelId;
const getChannelList = (state) => state.channelList;
const getIsSound = (state) => state.isSoundOn;
const getIsAudioPlay = (state) => state.isAudioPlay;
const getBettingAmount = (state) => state.bettingAmount;

export default {
	getBettingAmount,
	getDarkMode,
	getUserInfo,
    getChannelId,
	getChannelList,
    getIsSound,
	getIsAudioPlay
};
