<template>
  <v-container style="background: none !important">
    <v-toolbar flat dark style="
        background-color: transparent !important;
        text-align: center;
        margin-top: -75px;
      ">
    </v-toolbar>
    <v-card flat class="card-report">
      <v-card-text class="p-0 card-backround">
        <template>
          <v-form>
            <v-container>
              <v-row>
                <v-col sm="12" lg="12" md="12">
                  <template>
                    <HeaderResultBetting @child-checkbox="dateWinLoseFilterValue" />
                    <v-data-table style="
                        font-family: 'khmer mef1';
                        background-color: rgba(0, 0, 0, 0.6);
                      " hide-default-header class="elevation-1 theme--dark data-table" item-key="_id" mobile-breakpoint="NaN"
                      :headers="headersReportBetting" :footer-props="footerProps" :page.sync="dataRB.page"
                      :items="dataReportBetting.reports" :items-per-page.sync="dataRB.rowsPerPage"
                      :server-items-length="dataReportBetting.total_rows" :loading="getLoading"
                       @update:page="reportBetting"
                      @update:items-per-page="reportBetting" @update:sort-desc="reportBetting"
                      @click:row="(item) => dialogMethod(item)">
                      <template v-slot:header="{ props }">
                        <th v-for="(header, index) in props.headers" :key="index"
                          class="text-center pt-3 header-text-style">
                          {{ $t(`title.${header.text }`) }}
                        </th>
                      </template>

                      <template v-slot:[`header.amount`]="{ header }">
                        {{ header.text }} ({{ getUserCurrency() }})
                      </template>
                      <template v-slot:[`header.amount_win`]="{ header }">
                        {{ header.text }} ({{ getUserCurrency() }})
                      </template>

                      <template v-slot:[`item.no`]="{ item }">
                        <span>
                          {{ "#" + item.no }}
                        </span>
                      </template>

                      <template v-slot:[`item.channel_type`]="{ item }">
                        <v-btn small color="#2bd0b8" outlined>
                          <span class="v-btn__content">
                            {{ getChannelTypeName(item.channel_type) }}
                          </span>
                        </v-btn>
                      </template>

                      <template v-slot:[`item.date`]="{ item }">
                        {{ convertDateTime(item.date) }}
                      </template>

                      <template v-slot:[`item.total_amount`]="{ item }">
                        {{ currencyFormat(item.total_amount) }} 
                      </template>

                      <template v-slot:[`item.cast`]="{ item }">
                        <span v-if="item.cast == null" class="c-white">
                          0
                        </span>
                        <span v-else class="c-white">
                          {{ currencyFormat(item.cast) }}
                        </span>
                      </template>

                      <template v-slot:[`item.amount_win`]="{ item }">
                        <span v-if="item.amount_win == null || item.amount_win == 0" class="c-white">
                          0
                        </span>
                        <span v-if="item.amount_win > 0" class="c-white">
                          <v-chip label :class="ma - 2" color="primary" style="
                              padding: 10px;
                              color: white;
                              font-size: 12px;
                              height: 22px;
                            ">
                            {{ currencyFormat(item.amount_win) }}
                          </v-chip>
                        </span>
                        <span v-if="item.amount_win < 0" class="c-red">
                          <v-chip label :class="ma - 2" color="red" style="
                              padding: 10px;
                              color: white;
                              font-size: 12px;
                              height: 22px;
                            ">
                            {{ currencyFormat(item.amount_win) }}
                          </v-chip>
                        </span>
                      </template>
                    </v-data-table>
                  </template>
                </v-col>

                <!-- dialog1 -->
                <v-dialog :value="dialog1" max-width="1200" persistent dark transition="dialog-bottom-transition">
                  <v-card>
                    <v-toolbar>
                      <v-btn icon color="teal" dark style="font-size: 10px" @click="closeDialogDetail()">
                        <v-icon color="warning">mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-row class="pl-5">
                        <v-col cols="12" sm="12" md="12" class="text-left mt-4">
                          <v-toolbar-title>{{ "Invoice" }}</v-toolbar-title>
                        </v-col>
                        <!-- new -->
                        <v-col style="padding-bottom: 30px;">
                          <template>
                            <v-data-table :headers="headers2" mobile-breakpoint="NaN" hide-default-header
                              :hide-default-footer="true" :footer-props="footerProps" item-key="_id" 
                              :items="getInvoiceData ? getInvoiceData : []" :server-items-length="getInvoiceData ? getInvoiceData.total_amount : 0"
                              :loading="getLoading" :page.sync="data.page"
                              :items-per-page.sync="data.rows_per_page" @update:page="getData"
                              @update:items-per-page="getData"
                              @update:sort-desc="getData"
                              :loading-text="$t('datatable.loadingData')" class="elevation-1 data-table theme--dark"
                              @click:row="(item) => invoiceDataMethod(item)">
                              <template v-slot:header="{ props }">
                                <th v-for="(header, index) in props.headers" :key="index"
                                  class="text-center pt-3 header-text-style">
                                  {{ $t(`header.${ header.text }`) }}
                                </th>
                              </template>
                              <template v-slot:[`item`]="{ item }">
                                <tr style="text-align: center">
                                  <!-- <td>
                                    {{
                                      getInvoiceData.indexOf(item) +
                                      (data.page - 1) * data.rows_per_page +
                                      1
                                    }}
                                  </td> -->
                                  <td>{{ item.time }}</td>
                                  <td class="report-payout">
                                    {{
                                    moment(item.time).format("YYYYMMDD") +
                                    "#" +
                                    item.no
                                    }}
                                  </td>

                                  <!--change structure-->
                                  <td v-if="variableModuleName == 'LottoPlusss'">
                                    <div>
                                      <span v-if="
                                        item.bet_a &&
                                        item.bet_a.number.length > 0
                                      " :style="{ color: item.bet_a.color }">
                                        A
                                      </span>
                                      <span v-if="
                                        item.bet_b &&
                                        item.bet_b.number.length > 0
                                      " :style="{ color: item.bet_b.color }">
                                        B
                                      </span>
                                      <span v-if="
                                        item.bet_c &&
                                        item.bet_c.number.length > 0
                                      " :style="{ color: item.bet_c.color }">
                                        C
                                      </span>
                                      <span v-if="
                                        item.bet_d &&
                                        item.bet_d.number.length > 0
                                      " :style="{ color: item.bet_d.color }">
                                        D
                                      </span>
                                      <span v-if="
                                        item.bet_e &&
                                        item.bet_e.number.length > 0
                                      " :style="{ color: item.bet_e.color }">
                                        E
                                      </span>
                                      <span> : </span>

                                      <span style="
                                          font-weight: bold;
                                          text-overflow: ellipsis;
                                          white-space: nowrap;
                                          width: 50px;
                                          overflow: hidden;
                                        " v-if="
                                          item.bet_a.number.length > 0 ||
                                          item.bet_b.number.length > 0 ||
                                          item.bet_c.number.length > 0 ||
                                          item.bet_d.number.length > 0 ||
                                          item.bet_e.number.length > 0
                                        ">{{
                                        item.bet_a.label != null
                                        ? item.bet_a.label
                                        : item.bet_b.label != null
                                        ? item.bet_b.label
                                        : item.bet_c.label != null
                                        ? item.bet_c.label
                                        : item.bet_d.label != null
                                        ? item.bet_d.label
                                        : item.bet_e.label != null
                                        ? item.bet_e.label
                                        : ""
                                        }}
                                      </span>
                                    </div>
                                  </td>
                                  <td v-else-if="variableModuleName == 'm_yuki'">
                                    <span  v-for="(betType, i) in item.bet_type" :key="i">
                                      {{betType.name}}
                                    </span>
                                    <!-- <table>
                                      <tr v-for="(betType, i) in item.bet_type" :key="i">
                                        <td>
                                          {{betType.name}}
                                        </td>
                                      </tr>
                                    </table> -->
                                  </td>
                                  <td v-else-if="variableModuleName == 'm_lottery'">
                                    <div>
                                                        <span v-if="item.bet_a && item.bet_a.number.length >0" :style="{ color: item.bet_a.color }">
                                                            A
                                                        </span>
                                                        <span v-if="item.bet_b && item.bet_b.number.length >0" :style="{ color: item.bet_b.color }">
                                                            B
                                                        </span>
                                                        <span v-if="item.bet_c && item.bet_c.number.length >0" :style="{ color: item.bet_c.color }">
                                                            C
                                                        </span>
                                                        <span v-if="item.bet_d && item.bet_d.number.length >0" :style="{ color: item.bet_d.color }">
                                                            D
                                                        </span>
                                                        <span v-if="item.bet_e && item.bet_e.number.length >0" :style="{ color: item.bet_e.color }">
                                                            E
                                                        </span> 
                                                        <span>
                                                            :
                                                        </span>
                                                    
                                                        <span style="font-weight: bold;" v-if="item.bet_a.number.length > 0 
                                                            || item.bet_b.number.length > 0 || item.bet_c.number.length > 0 || item.bet_d.number.length > 0 
                                                            || item.bet_e.number.length > 0">{{ item.bet_a.label != null ? item.bet_a.label :
                                                                                                                        item.bet_b.label != null ? item.bet_b.label :
                                                                                                                        item.bet_c.label != null ? item.bet_c.label :
                                                                                                                        item.bet_d.label != null ? item.bet_d.label :
                                                                                                                        item.bet_e.label != null ? item.bet_e.label : ""
                                                            }} </span>
                                                        
                                                    </div>
                                  </td>
                                  <td v-else>
                                    <div v-if="variableModuleName == 'Yuki'" class="d-flex justify-content-start mt-2">
                                      <div class="align-items-between">
                                        <span color="warning" class="my-auto ml-1 mb-2">
                                          {{item.bet_type.name }}
                                        </span>
                                      </div>
                                    </div>
                                    <div v-else-if="variableModuleName == 'Klaklouk'">
                                      <div :class="_bettingColor(item.channel_type, item.bet_type.color)" style="border:0px!important">
                                        <v-img color="warning" class=" mx-auto" style="color:white!important; width:50px!important; height:50px;"
                                        :src="_klakloukNameToimg($t(sameGameFindName(item.channel_type) + item.bet_type.name))"/>
                                      </div>
                                    </div>
                                    <div v-else>
                                      <div class="align-items-between pa-2" :class="_bettingColor(item.channel_type, item.bet_type.color)" style="border:0px!important">
                                        <span color="warning" style="color:white!important">
                                          {{ $t(sameGameFindName(item.channel_type) + item.bet_type.name) }}
                                        </span>
                                      </div>
                                    </div>
                                  </td>

                                  <td class="report-amount">
                                    {{ currencyFormat(item.total_amount) }}
                                  </td>

                                  <td v-if="item.is_release_result" :class="item.total_amount_win < 0
                                      ? 'report-amount-lose'
                                      : item.total_amount_win > 0
                                      ? 'report-amount-win'
                                      : '' ">
                                    {{ currencyFormat(item.total_amount_win) }}
                                  </td>
                                  <td v-else :class="
                                    item.total_amount_win < 0
                                      ? 'report-amount-lose'
                                      : item.total_amount_win > 0
                                      ? 'report-amount-win'
                                      : '' ">
                                    --
                                  </td>
                                  <td v-if="item.is_release_result">
                                    {{ currencyFormat(item.total_cast) }}
                                  </td>
                                  <td v-else>--</td>
                                  <td v-if="variableModuleName == 'Klaklouk'">
                                    <div class="align-items-between row ma-0" v-if="item.bet_result.is_win !=null">
                                        <v-img color="warning" class=" " style="color:white!important; width:40px!important; height:61px!important;"
                                        :src="_klakloukNameToimg($t(sameGameFindName(item.channel_type) + item.bet_result.result1_name))"/>
                                        <v-img color="warning" class=" mx-2" style="color:white!important; width:40px!important; height:61px;"
                                        :src="_klakloukNameToimg($t(sameGameFindName(item.channel_type) + item.bet_result.result2_name))"/>
                                        <v-img color="warning" class=" " style="color:white!important; width:40px!important; height:61px;"
                                        :src="_klakloukNameToimg($t(sameGameFindName(item.channel_type) + item.bet_result.result3_name))"/>
                                    </div>
                                    <div v-else>
                                      ---
                                    </div>
                                  </td>
                                  <td v-else-if="variableModuleName == 'm_yuki'">
                                    --
                                  </td>
                                  <td v-else-if="variableModuleName == 'm_lottery'">
                                    --
                                  </td>
                                  <div v-else-if="variableModuleName == 'han2' || variableModuleName == 'han3'" class="pt-1">
                                    {{ _getResultClass(item.bet_result.result1_name) }},
                                    {{ _getResultClass(item.bet_result.result2_name) }},
                                    {{ _getResultClass(item.bet_result.result3_name) }}
                                  </div>
                                  <td v-else>
                                    <div>    
                                      <div v-if="item.bet_result.name" class="align-items-between pa-2" :class="_bettingColor(item.channel_type, item.bet_result.color)" style="border:0px!important">
                                        <span color="warning" style="color:white!important">
                                          {{ $t(sameGameFindName(item.channel_type) + item.bet_result.name) }}
                                        </span>
                                      </div>
                                      <div v-else>
                                        ---
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </template>
                        </v-col>
                        <!-- end_new -->
                      </v-row>
                    </v-card-text>

                    <v-card-text v-if="variableModuleName == 'm_yuki' || variableModuleName == 'm_lottery'">
                      <v-row class="pl-5">
                        <v-col @click="showDeail()" cols="12" sm="12" md="12" class="text-left mt-4">
                          <v-toolbar-title >{{ "Show Invoice Detail" }}</v-toolbar-title>
                        </v-col>
                        <!-- new -->
                        <v-col style="padding-bottom: 30px;" v-show="this.isShow">
                          <template>
                            <v-data-table 
                              :headers="headers2" mobile-breakpoint="NaN" hide-default-header
                              :hide-default-footer="true" :footer-props="footerProps"
                              :items="getInvoiceDetailData ? getInvoiceDetailData : []"
                              :loading="detailLoading" :sort-desc.sync="dataDetail.descendingFormat"
                              :sort-by.sync="dataDetail.sortByFormat" :page.sync="dataDetail.page"
                              :items-per-page.sync="dataDetail.rows_per_page" @update:page="getData"
                              @update:items-per-page="getData" @update:sort-desc="getData"
                              :loading-text="$t('datatable.loadingData')" class="elevation-1 data-table theme--dark">
                              <template v-slot:header="{ props }">
                                <th v-for="(header, index) in props.headers" :key="index"
                                  class="text-center pt-3 header-text-style">
                                  {{ $t(`header.${ header.text }`) }}
                                </th>
                              </template>

                              <template v-slot:[`item`]="{ item }">
                                <tr style="text-align: center">
                                  <td>{{ item.time }}</td>
                                  <td class="report-payout">
                                    {{moment(item.time).format("YYYYMMDD") + "#" + item.no }}
                                  </td>

                                  <td>
                                    <div v-if="variableModuleName == 'm_yuki'" class="align-items-between" :class="item.bet_type.color">
                                      <span color="warning">
                                        {{ item.bet_type.name }}
                                      </span>
                                    </div>
                                    <div v-else-if="variableModuleName == 'm_lottery'">
                                      <span :style="{ color: item.bet_color}">
                                        {{ item.bet_type }}:{{ item.betting }}
                                      </span>
                                    </div>
                                  </td>

                                  <td class="report-amount">
                                    {{item.amount}}
                                  </td>
                                  <!-- win / lose -->
                                  <td >
                                    <div v-if="item.is_release_result">
                                      <span :class="item.bet_result.is_win ? 'report-amount-win' : 'report-amount-lose'">
                                        {{ item.amount}}
                                      </span>
                                    </div>
                                    <div v-else>
                                      --
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      {{ item.return_amount }}
                                    </div>
                                  </td>
                                  
                                  <td>
                                    <div v-if="item.is_release_result" >
                                      <div v-if="variableModuleName == 'm_yuki'" class="align-items-between">
                                        <span color="warning">
                                          {{ item.bet_result? item.bet_result.name : '--' }}
                                        </span>
                                      </div>
                                      <div v-else-if="variableModuleName == 'm_lottery'">
                                        <span v-if="item.bet_type == 'A'" :style="{ color: item.bet_result.a.color}">
                                          {{ item.bet_result.a.number }}
                                        </span>
                                        <span v-if="item.bet_type == 'B'" :style="{ color: item.bet_result.b.color}">
                                          {{ item.bet_result.b.number }}
                                        </span>
                                        <span v-if="item.bet_type == 'C'" :style="{ color: item.bet_result.c.color}">
                                          {{ item.bet_result.c.number }}
                                        </span>
                                        <span v-if="item.bet_type == 'D'" :style="{ color: item.bet_result.d.color}">
                                          {{ item.bet_result.d.number }}
                                        </span>
                                        <span v-if="item.bet_type == 'E'" :style="{ color: item.bet_result.e.color}">
                                          {{ item.bet_result.e.number }}
                                        </span>
                                      </div>
                                  
                                    </div>
                                    <div v-else>
                                      --
                                    </div>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </template>
                        </v-col>
                        <!-- end_new -->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <!-- end dialog1 -->
              </v-row>
            </v-container>
          </v-form>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
import headersReportBetting from "./_api/columnsReportBetting";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
// import PrintReportBetting from "./components/PrintReportBetting.vue";
import moment from "moment";
import axios from "axios";

export default {
  components: {
    HeaderResultBetting: () => import("./components/HeaderResultBetting.vue"),
    // PrintReportBetting,
  },
  data() {
    return {
      headersReportBetting: headersReportBetting,
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      channelType: 1,
      loading: false,
      detailLoading: false,
      dialog: false,
      isShow: false,
      tempVal: {},
      dataRB: {
        page: 1,
        rowsPerPage: 10,
      },
      dateFilterRB: {},
      dataDetail: {
        rows_per_page: 200,
        page: 1,
        end_date: "",
        start_date: "",
      },
      data: {
        rows_per_page: 10,
        page: 1,
        end_date: "",
        start_date: "",
      },
      headers: [
        { text: "header.no", value: "no" },
        { text: "header.date_time", value: "date" },
        { text: "header.bettingType", value: "module_name" },
        { text: "header.post", value: "channel_type" },
        { text: "header.game_no", value: "no" },
        { text: "header.group", value: "group" },
        { text: "header.balance", value: "total_amount" },
        { text: "header.winLose", value: "amount_win" },
        { text: "header.cast", value: "cast" },
        { text: "header.result", value: "status" },
        // { text: "header.action", value: "action" },
      ],
      headers2: [
        // { text: "no", align: "start", value: "no" },
        { text: "date", value: "date" },
        { text: "game", value: "no" },
        { text: "bettingType", value: "bet_type.name" },
        { text: "amount_betting", value: "total_amount" },
        { text: "winLose", value: "total_amount_win" },
        { text: "total_cast", value: "total_cast" },
        { text: "result", value: "bet_result" },
        // { text: "action", value: "action" },
      ],
      headersDetail: [
        { text: "header.no", align: "start", value: "no" },
        { text: "header.date", value: "date" },
        { text: "header.type", value: "bet_result.name" },
        { text: "header.amount", value: "total_amount" },
      ],
      headersDetail2: [
        //yuki
        { text: "header.no", align: "start", value: "no" },
        { text: "header.date", value: "date" },
        { text: "header.type", value: "bet_result.name" },
        { text: "header.amount", value: "total_amount" },
        { text: "header.result", value: "bet_result" },
        { text: "header.winLose", value: "payout" },
      ],
      dataRequest: {
        descendingFormat: [true],
        sortByFormat: ["_id"],
        descending: true,
        sortBy: null,
        page: 1,
        rows_per_page: 10,
        getLoadingDetail: false,
        invoice_id: null,
      },
      dialog3: false,
      dialog2: false,
      dialog1: false,
      variableModulePath: "",
      variableModulePathDetail: "",
      variableModuleName: "",
      getLoadingDetail: false,
      detailData: [],
      getInvoiceData: [],
      getInvoiceDetailData: [],
      startDate: null,
      endDate: null,
      v_startDate: null,
      v_endDate: null,
      selectedOption: null,
      moduleBetId: null,
    };
  },

  created() {
    // this.getUserInfo();
    this.reportBetting();
  },

  item: {
    type: Object,
    default: null,
  },

  computed: {
    ...mapGetters("$_allstatement", {
      dataReportBetting: "getReportBetting",
    }),
    ...mapGetters("$_allstatement", ["getLoading"]),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  mounted() {
    this.channelType = this.$cookies.get("sbc_channel_type")
      ? this.$cookies.get("sbc_channel_type")
      : 1;
  },
  methods: {
    moment,
    _getResultClass(x) {
      switch (x) {
        case 'Banker 1':
          return 'B1'
        case 'Banker 2':
            return 'B2'
        case 'Banker 3':
            return 'B3'
        case 'Player 1':
            return 'P1'
        case 'Player 2':
          return 'P2'
        case 'Player 3':
            return 'P3'
        case 'CANCEL':
            return 'Cancel'
          case 'Tie 1':
          return"T1"
          case 'Tie 2':
          return "T2"
          case 'Tie 3':
          return "T3"
          default:
          return ''
      }},
    showDeail(){
      if(!this.isShow){
        this.detailLoading = true;
        this.invoiceDataMethod(this.tempVal);
      }
    },
    closeDialogDetail(){
      this.isShow = false;
      this.dialog1 = false
    },
    async dialogMethod(val) {
      this.tempVal = val;
      // console.log("channel_type:", val.channel_type);
      this.moduleBetId = val.module_bet_id;
      this.channelType = val.channel_type;
      this.dialog1 = true;
      // console.log("val.channel_type", val.channel_type);
      if (val.channel_type == "10") {
        this.variableModuleName = "LottoPlus";
        this.variableModulePath = "report/lotto/?module_bet_id=";
        this.variableModulePathDetail = "report/lotto/?module_bet_id=";
      } else if (val.channel_type == "11") {
        this.variableModuleName = "Yuki";
        this.variableModulePath = "report/yuki/?module_bet_id=";
        this.variableModulePathDetail = "report/yuki/?module_bet_id=";
      } else if (
        val.channel_type == "1" ||
        val.channel_type == "2" ||
        val.channel_type == "3" ||
        val.channel_type == "4" ||
        val.channel_type == "15"||
        val.channel_type == "16"
      ) {
        this.variableModuleName = "CockFight";
        this.variableModulePath = "report/cock/?module_bet_id=";
      } else if (val.channel_type == "12" || val.channel_type == "17") {
        this.variableModuleName = "Apong";
        this.variableModulePath = "report/abong/?module_bet_id=";
      } else if (val.channel_type == "8" || val.channel_type == "9" ||  val.channel_type == "19") {
        this.variableModuleName = "Dragon";
        this.variableModulePath = "report/dragon/?module_bet_id=";
      } else if (val.channel_type == "5") {
        this.variableModuleName = "Klaklouk";
        this.variableModulePath = "report/klaklouk/?module_bet_id=";
      } else if (val.channel_type == "6" || val.channel_type == "7" || val.channel_type == "18") {
        this.variableModuleName = "Baccarat";
        this.variableModulePath = "report/baccarat/?module_bet_id=";
      } else if (val.channel_type == "13") {
        this.variableModuleName = "Fatan";
        this.variableModulePath = "report/fantan/?module_bet_id=";
        console.log(this.variableModulePath)
      } else if (val.channel_type == "14") {
        this.variableModuleName = "Taisiev";
        this.variableModulePath = "report/taisiev/?module_bet_id=";
      }
      else if (val.channel_type == "20") {
        this.variableModuleName = "han2";
        this.variableModulePath = "report/han2/?module_bet_id=";
      } 
      else if (val.channel_type == "21") {
        this.variableModuleName = "han3";
        this.variableModulePath = "report/han3/?module_bet_id=";
      } 
       else if (val.channel_type == "90") {
        this.variableModuleName = "report";
        this.variableModulePath = "m_lotto/report/?module_bet_id=";
      } else if (val.channel_type == "91") {
        this.variableModuleName = "m_yuki";
        this.variableModulePath = "m_yuki/report/?module_bet_id=";
      } else if (val.channel_type == "92") {
        this.variableModuleName = "m_lottery";
        this.variableModulePath = "m_lottery/report/?module_bet_id=";
      } else return '';
      let requestUrl = '';
      if(val.channel_type =='90'){
        requestUrl = process.env.VUE_APP_SLOT_URL
      }else if(val.channel_type =='91'){
        requestUrl = process.env.VUE_APP_SLOT_URL
      }else if(val.channel_type =='92'){
        requestUrl = process.env.VUE_APP_SLOT_URL
      }else{
        requestUrl = process.env.VUE_APP_API_URL
      }
      //request API
      let token2 = this.$cookies.get("sbc_token");
      await axios
        .get(
          requestUrl +
          this.variableModulePath +
          this.moduleBetId,
          {
            headers: {
              Authorization: token2,
              "channel-type": this.channelType,
            },
          }
        )
        .then((response) => {
          if (response.data.code) {
            console.log('herer', val.channel_type)
            this.getInvoiceData = response.data.data;
            this.getInvoiceData[0].channel_type = val.channel_type;
            console.log('herer', this.getInvoiceData)
          }
        })
        .catch((error) => console.log(error));
    },
    async invoiceDataMethod(val) {
      let slotModulePath = "";
      let requestUrl = process.env.VUE_APP_SLOT_URL
      if (val.channel_type == "91") {
        slotModulePath = "m_yuki/report/detail?module_bet_id="+val.module_bet_id;
      }else if(val.channel_type == "92"){
        slotModulePath = "m_lottery/report/detail?module_bet_id="+val.module_bet_id;
      }
      //request API
      let token2 = this.$cookies.get("sbc_token");
      await axios.get(requestUrl + slotModulePath,{
            headers: {
              Authorization: token2,
              "channel-type": this.channelType,
            },
        }).then((response) => {
          if (response.data.code) {
            this.detailLoading = false;
            this.isShow = true;
            this.getInvoiceDetailData = response.data.data;
          }
        })
        .catch((error) => console.log(error));
    },
    updatePage() {
      this.getLoadingDetail = true;
      this.loadDetailData(this.invoice_id);
      this.getLoadingDetail = false;
    },
    async loadDetailData(_id) {
      //console.log("ID..:", _id);
      this.detailData = [];
      this.getLoadingDetail = true;

      this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id._id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);
      let token = this.$cookies.get("sbc_token");
      // let request = new Request;
      process.env.VUE_APP_API_URL + this.variableModulePath + this.moduleBetId,
        await axios
          .get(
            process.env.VUE_APP_API_URL + this.variableModulePathDetail + _id,
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .then((response) => {
            if (response.data.code) {
              this.detailData = response.data.data;
              this.getLoadingDetail = false;
            }
          })
          .catch((error) => console.log(error));
    },

    dateWinLoseFilterValue: function (params) {
      this.dateFilterRB = params;
      this.dataRB = {
        page: 1,
        rowsPerPage: 10,
      };
      this.reportBetting();
    },
    reset() {
      this.$refs.form.reset();
    },

    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    getUserCurrency() {
      if (this.userInfor.currency_type === 1) {
        return "$";
      } else if (this.userInfor.currency_type === 2) {
        return "៛";
      } else if (this.userInfor.currency_type === 3) {
        return "฿";
      }
    },
    getChannelTypeName(channel) {
      if (channel === "1") {
        return "🐔 1";
      } else if (channel == "2") {
        return "🐔 2";
      } else if (channel == "3") {
        return "🐔 3";
      } else if (channel == "4") {
        return "🐔 4";
      } else if (channel == "5") {
        return "🦀 1";
      } else if (channel == "6") {
        return "🃏 1";
      } else if (channel == "7") {
        return "🃏 2";
      }
        else if (channel == "18") {
        return "🃏 3";
      
      } else if (channel == "8") {
        return "🐲 1";
      } else if (channel == "9") {
        return "🐲 2";
      } else if (channel == "19") {
        return "🐲 3";
      }
      else if (channel == "10") {
        return "🎱 1";
      } else if (channel == "11") {
        return "☯️ 1";
      } else if (channel === "12") {
        return "🎲 1";
      } else if (channel === "13") {
        return "⚃ 1";
      } else if (channel === "14") {
        return "⚅ 2";
      } else if (channel === "15") {
        return "🥊 1";
      } else if (channel === "16") {
        return "🛞 1";
      } else if (channel === "17") {
        return "🎲 1";
      } else if (channel === "90") {
        return "🎰 Lotto";
      }
      else if (channel == "91") {
        return "🎰 Yuki";
      }
      else if (channel == "92") {
        return "🎰 PP78";
      }
      else if (channel === "20") {
        return "han 2";
      }
      else if (channel === "21") {
        return "Han 3";
      }
       else {
        return channel;
      }
    },
    // async getUserInfo() {
    //   await this.fetchUserInfo();
    // },
    async reportBetting() {
      let body = {};
      if (
        this.dateFilterRB.startDate != null &&
        this.dateFilterRB.endDate != null
      ) {
        body = {
          rows_per_page: this.dataRB.rowsPerPage,
          page: this.dataRB.page,
          start_date: this.dateFilterRB.startDate,
          end_date: this.dateFilterRB.endDate,
        };
      } else {
        body = {
          rows_per_page: this.dataRB.rowsPerPage,
          page: this.dataRB.page,
        };
      }
      await this.fetchReportBetting(body);
    },
    async getData(type) {
      // console.log("type: " + type);
      if (type === "TODAY") {
        let _today = moment(new Date()).format("YYYY-MM-D"); //new Date().toISOString().slice(0, 10)
        //console.log("_today:", _today);
        this.startDate = _today;
        this.endDate = _today;
        this.reportBetting();
      } else if (type === "LAST_DAY") {
        let last_day = moment().subtract(1, "days").format("YYYY-MM-D");
        this.startDate = last_day;
        this.endDate = last_day;
        this.reportBetting();
      } else if (type === "THIS_WEEK") {
        let startOfWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
        let endOfWeek = moment().endOf("isoWeek").format("YYYY-MM-DD");
        this.startDate = startOfWeek;
        this.endDate = endOfWeek;
        this.reportBetting();
      } else if (type === "LAST_WEEK") {
        let startOfLastWeek = moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
        let endOfLastWeek = moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format("YYYY-MM-DD");
        this.startDate = startOfLastWeek;
        this.endDate = endOfLastWeek;
        this.reportBetting();
      } else if (type === "THIS_MONTH") {
        let startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
        let endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
        this.startDate = startOfMonth;
        this.endDate = endOfMonth;
        this.reportBetting();
      } else if (type === "LAST_MONTH") {
        let startOfLastMonth = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        let endOfLastMonth = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        this.startDate = startOfLastMonth;
        this.endDate = endOfLastMonth;
        this.reportBetting();
      } else if (type === "ALL") {
        this.startDate = "";
        this.endDate = "";
        this.reportBetting();
      } else if (type === "search") {
        // let a = this.$cookies.get("local");
        // console.log('lang:', a);
        this.reportBetting();
      }
    },
    ...mapActions("$_myinfo", ["fetchMyInfo"]),
    ...mapMutations("$_allstatement", ["REPORT_BETTING"]),
    ...mapActions("$_allstatement", ["fetchReportBetting"]),
  },
};
</script>

<style>
.header {
  font-size: 16px;
}

.report-amount-lose {
  color: red;
  font-weight: 700;
  font-size: 18px !important;
}

.report-amount-win {
  color: yellow;
  font-weight: 700;
  font-size: 18px !important;
}

.report-amount {
  color: aqua;
  font-weight: 700;
  font-size: 18px !important;
}

.container {
  height: auto !important;
}

.header-text-style {
  font-weight: 600;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.698);
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(128, 128, 128, 0.5);
}

.theme--light.v-select .v-select__selections {
  color: white !important;
}

.v-data-footer__icons-before {
  color: white !important;
}

.theme--light.v-btn.v-btn--icon {
  color: white !important;
}

.theme--light.v-btn.v-btn--disabled .v-icon {
  color: white !important;
}

.theme--light.v-btn.v-btn--disabled,
.theme--light.v-btn.v-btn--disabled .v-btn__loading,
.theme--light.v-btn.v-btn--disabled .v-icon {
  color: rgba(256, 256, 256, 0.26) !important;
}
</style>