const LATEST_FIVE_RESULTS = (state, data) => {
    state.lastFiveResults = data;
};
const CURRENT_BETTING = (state, data) => {
    state.currentBetting = data;
};
const GET_BUTTON = (state, data) => {
    state.buttonMoney = data;
};
const APPEND_CURRENT_BETTING = (state, data) => {
    state.currentBetting.list.unshift(data);
};
const PREVIOUS_BETTING = (state, data) => {
    state.previousBetting = data;
};
const CURRENT_GAME = (state, data) => {
    state.currentGame = data;
};
const HEAD_TAIL_TYPE = (state, data) => {
    state.headTailType = data;
};
const RANGE_TYPE = (state, data) => {
    state.rangeType = data;
};
const LATEST_ONE_RESULTS = (state, data) => {
    state.lastOneResult = data;
};
const LATEST_TWENTY_RESULTS = (state, data) => {
    state.lastTwentyResult = data;
};
const IS_BETTING = (state, data) => {
    state.is_Betting = data;
};
const UPDATE_TYPE_MASTER = (state, data) => {
    state.typeMaster = data;
};
const FIRST_SIX_BTN = (state, data) => {
    state.firstSixBtn = data;
    // console.log(state.firstSixBtn)
};
const SIX_TO_TWELVE = (state, data) => {
    state.sixToTwelve = data;
};
const RANGE_SMALL = (state, data) => {
    state.rangeSmall = data;
};
const RANGE_BIG = (state, data) => {
    state.rangeBig = data;
};
const SMALL_BIG = (state, data) => {
    state.smallBig = data;
};
const ODD_PAIR = (state, data) => {
    state.oddPair = data;
};
const BLUE_RED = (state, data) => {
    state.blueRed = data;
};
const UPDATE_TOTAL_INVOICE = (state, data) => {
    state.totalInvoice = data;
};

const UPDATE_YUKI_LIMIT = (state, data) => {
    state.limitYukiBetting = data;
};
const UPDATE_SELECT_BTN = (state, data) => {
    state.selectBtn = data;
};

export default {
    UPDATE_SELECT_BTN,
    UPDATE_YUKI_LIMIT,
    LATEST_FIVE_RESULTS,
    LATEST_TWENTY_RESULTS,
    CURRENT_BETTING,
    PREVIOUS_BETTING,
    CURRENT_GAME,
    HEAD_TAIL_TYPE,
    RANGE_TYPE,
    LATEST_ONE_RESULTS,
    APPEND_CURRENT_BETTING,
    IS_BETTING,
    GET_BUTTON,
    UPDATE_TYPE_MASTER,
    FIRST_SIX_BTN,
    SIX_TO_TWELVE,
    RANGE_SMALL,
    RANGE_BIG,
    SMALL_BIG,
    ODD_PAIR,
    BLUE_RED,
    UPDATE_TOTAL_INVOICE
};
