<template>
    <v-container fluid class="bg-surface-variant" >
        <v-row no-gutters class="lotto-border">
          <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" class="lotto-border yuki-order-1" style="overflow: auto; ">
            <LeftSection/>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9" xxl="9" class="lotto-border yuki-order-2" style="overflow: auto; ">
            <div>
              <Tabs />
            </div>
            <GameContent  :passClientResult="clientResult"
                          :passClientRandom="clientRandom"
                          :passRandomNumber="randomNumber"
                          :passClientTime="clientTime">
            </GameContent>
            <GameResult/>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import GameResult from "./components/GameResult.vue";
import GameContent from "./components/GameContent.vue";
import LeftSection from "./components/leftSection.vue";

import {  mapState, mapGetters, mapMutations, mapActions  } from "vuex";
export default {
components: {
  LeftSection,
  GameContent,
  GameResult,
},
data() {
  return {
    message : "Hello world",
    orderResult : {},
    orderResultUnderOver:{},
    clientTime: {
      is_countdown: null,
      is_open_betting: null,
      time: null,
    },
    clientRandom: {
                "randmon_time": null,
                "is_open": false
            },
    clientResult: null,
    randomNumber :0
  }
},
watch: {
  channelId: async function (newVal, oldVal) {
    if (newVal != oldVal) {
      this.channelList = this.$cookies.get("sbc_user").channel_list;
      this.$socket.removeAllListeners()
      this.$socket.disconnect()
      this.connectedSocket();
    }
  }
},
computed: {
  ...mapGetters("$_modules", {
    channelId:"channelId",
    isSound: "getIsSound"
  }),
  ...mapGetters("$_yuki160s", {
    lastTwentyResults: "getLastTwentyResult",
    TypeMaster: "getTypeMaster"
  }),
  ...mapState("$_modules", {
    channelId: "channelId",
  }),
},
created() {
  this.connectedSocket();
  this._initialData();
},
methods:{
  connectedSocket() {
    this.$connectSocket();
    this.listenerSocket()
  },
  _initialData() {
    this.fetchUserInfo();
    this.fetchLastResults();
    this.fetchCurrentBetting();
    this.fetchCurrentGame();
    this.fetchPreviousBetting();
    this.fetchTypeMaster();
    this.fetchButton();
    this.fetchLimit()
  },
  listenerSocket(){
     // socket get random number, total number,
    this.$socket.on("client-random", (data) => {
      // console.log(`socket on client-random: ${JSON.stringify(data)}`);
      if (data && data.randmon_time != null ) {
        this._clearResult();
                      let interval;
                      const _this = this;
                      this.clientRandom = data;
                      this.clientTime = null;
                      interval = setInterval(function () {
                          let result = parseInt(Math.random() * (12 - 1) + 1);
                          _this.randomNumber = result;
                      }, 100);
                          setTimeout(() => {
                              clearInterval(interval);
                          }, 1300);
                  }
    });
    // socket get time,  is betting, is coldown
    this.$socket.on("client-time", (data) => {
      // console.log(`socket on client-time: ${JSON.stringify(data)}`);
      if (data) {
        // define client time to display count down time at play zone
        this.clientTime = data;
        // console.log(this.clientTime)
        // play audio sound
        if (data.time == 59) {
          this._playSoundBetDown();
        }
        if (data.time < 11 && data.time !== 0) this._playSoundTing();
        if (data.time == 0) this._playSoundGoodLuck();
      }
    });
    // get new game
    this.$socket.on("client-new-game", (data) => {
      // console.log(`socket on client-newgame: ${JSON.stringify(data)}`);
      if (data) {
        this.clientRandom = [];
        this.bonus = [];

        this.randomNumber = null;
        // this.$confetti.stop();
        this.UPDATE_TOTAL_INVOICE(0);
        this._initialData();
      }
    });
    // get game result and bonus
    this.$socket.on("client-result", (data) => {
      // console.log(`socket on client-result: ${JSON.stringify(data)}`);
//         {
//   "_id": "6463b66e39178ba6a8ccddfa",
//   "bonus_between_year": {
//     "is_bonus": false,
//     "year_number": 18,
//     "payout": 0
//   },
//   "between_year": {
//     "year_name_kh": "កុរ",
//     "year_name_vn": "Lợn",
//     "year_name_tb": "กุน",
//     "year_name_en": "Pig",
//     "year_number": 12
//   },
//   "small_large": {
//     "year_name_kh": "ក្រាស់",
//     "year_name_vn": "Đặc",
//     "year_name_tb": "หนา",
//     "year_name_en": "Thick",
//     "year_number": 14
//   },
//   "range": {
//     "year_name_kh": "10-11-12",
//     "year_name_vn": "10-11-12",
//     "year_name_tb": "10-11-12",
//     "year_name_en": "10-11-12",
//     "year_number": 18
//   },
//   "blue_red": {
//     "year_name_kh": "ក្រហម",
//     "year_name_vn": "đỏ",
//     "year_name_tb": "สีแดง",
//     "year_name_en": "red",
//     "year_number": 19
//   },
//   "odd_even": {
//     "year_name_kh": "គូ",
//     "year_name_vn": "Đôi",
//     "year_name_tb": "คู่",
//     "year_name_en": "even",
//     "year_number": 22
//   },
//   "no": 40175
// }
      if (data) {
        this.clientResult = data;
      }
    });
    this.$socket.on("client-bonus", (data) => {
      // console.log(`socket on client-bonus: ${JSON.stringify(data)}`);
      // {"is_bonus":false,"year_number":18,"payout":0}
      if (data) {
        this.bonus = data;
        if (this.bonus.is_bonus) {
          // this.$confetti.start();
          // this._playSoundCongratulation();
        }
      }
    });
    this.$socket.on("client-close", (data) => {
      // console.log(`socket on client-close: ${JSON.stringify(data)}`);
      // {"is_close":true}
      if (data) {
        this.is_close = data;
      }
    });
    // this.$socket.on("client-reset", (data) => {
      // console.log(`socket on client-reset: ${JSON.stringify(data)}`);
      // if (data) {

      // }
    // });
    this.$socket.on("client-update-user-info", (data) => {
      // console.log(`socket on client-update-user-info: ${JSON.stringify(data)}`);
      if (data) {
        if (data.is_suspend) {
          this.$toastr.e(`Your account have been blooked!`)
          this.onLogout()
        }
        this.updateUserInfo(data);
      }
    });
  },
  onLogout() {
    this.$socket.removeAllListeners()
    this.$socket.disconnect()
    this.$cookies.remove('sbc_channel_type')
    this.$cookies.remove('sbc_token')
    this.$router.push("/login")
  },
  _clearResult() {
            new Promise((resolve) => setTimeout(resolve, 5000));
            this.clientResult = null;
        },
  _playSoundBang() {
    if(this.isSound){
      let audio = new Audio("/sound/bangbang.mp3");
      let promise = audio.play();
      if (promise) {
        promise.catch(function () {});
      }
    }
  },
  _playSoundBetDown() {
    if(this.isSound){
      let audio = new Audio("/sound/betdown_please.mp3");
      let promise = audio.play();
      if (promise) {
        promise.catch(function () {});
      }
    }
  },
  _playSoundTing() {
    if(this.isSound){
      let audio = new Audio("/sound/ting1s.mp3");
      let promise = audio.play();
      if (promise) {
        promise.catch(function () {});
      }
    }
  },
  _playSoundGoodLuck() {
    if(this.isSound){
      let audio = new Audio("/sound/good_luck.mp3");
      let promise = audio.play();
      if (promise) {
        promise.catch(function () {});
      }
    }
  },
  _playSoundCongratulation() {
    if(this.isSound){
      let audio = new Audio("/sound/congratulations.mp3");
      let promise = audio.play();
      if (promise) {
        promise.catch(function () {});
      }
    }
  },
  // ...mapMutations("$_machine", [
  //   "LATEST_FIVE_RESULTS",
  //   "CURRENT_BETTING",
  //   "PREVIOUS_BETTING",
  //   "COUNT_TIME",
  // ]),
  // ...mapActions("$_machine", [
  //   "fetchLastResults",
  //   "fetchCurrentBetting",
  //   "fetchPreviousBetting",
  //   "fetchCurrentGame",
  //   "SocketInAction",
  //   "fetchButton",
  // ]),
  ...mapMutations("$_yuki160s", [
    "LATEST_FIVE_RESULTS",
    "CURRENT_BETTING",
    "PREVIOUS_BETTING",
    "COUNT_TIME",
    "UPDATE_TYPE_MASTER",
    "UPDATE_TOTAL_INVOICE"

  ]),
  ...mapActions("$_yuki160s", [
    "fetchLastResults",
    "fetchCurrentBetting",
    "fetchPreviousBetting",
    "fetchCurrentGame",
    "SocketInAction",
    "fetchButton",
    "fetchTypeMaster",
    "fetchLimit"
  ]),
  ...mapActions("$_modules", [
    "fetchUserInfo",
    "updateUserInfo"
  ]),
},  
name: 'IndexLotto',
async beforeRouteLeave(to, from, next) {
  this.$socket.removeAllListeners();
  this.$socket.disconnect();
  next();
}
}
</script>

<style>
.game-no-style {
  height: 70px;
  background-color: #cc9629;
  color: white;
  text-shadow: 0px 1px black;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 2rem;
  letter-spacing: .5px;
  margin: 5px 0px;
  border: 3px solid #0c4588;
}

.user-balance-style {
  height: 70px;
  color: white;
  text-shadow: 0px 1px black;
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: .5px;
  margin: 5px 0px;
  border: 3px solid #0c4588;
}

.balance-value {
  background-color: #cc9629;
  height: 30px;
  border-bottom: 3px solid #0c4588;
}

.user-balance-style :nth-child(2) {
  background-color: white;
  height: 50px;

}

.user-balance-style :nth-child(2)>span {
  background-color: white;
  color: goldenrod;
  font-weight: 900;
  font-size: 1.2rem;
  border-bottom: 0px;
}

.current-game-limit {
  flex: 0 0 49%;
  max-width: 49%;
  height: 145px;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 2rem;
  letter-spacing: .5px;
  margin: 5px 0px 0px 0px;
  border: 3px solid #0c4588;
  color: black
}

.limit-title {
  background-color: #0c4588;
  height: 35px;
  color: white;

}



.each-button-height-animal {
  height: 50% !important;
}

.each-button-left {
  border: 4px solid white;
  border-right: 2px solid white;
  border-left: 0px
}

.each-button-right {

  border: 4px solid white;
  border-left: 2px solid white;
  border-right: 0px
}



.betting-payout {
  font-size: 1rem;
  font-weight: 600;
  
}

.d-result-style {
  width: 100%;
  height: 94.5%;
  border: 3px solid #0c4588;
  position: relative;
  overflow: hidden;
  
}


.d-result-curtain-open {
  width: 100%;
  height: 440px!important;
  display: block;
  z-index: 199;
  position: absolute;
  top: 0;
}

.d-result-curtain-close {
  width: 100%;
  height: 106%;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 99;
  position: absolute;
  top: 0px;
}

.rotate {
  width: 100%;
  animation: rotation 10s infinite linear;
  overflow: hidden;
  z-index: 0;
}



.yuki-clear-betting:hover {
  cursor: pointer;
}

input {
  padding: 0 15px;
  word-spacing: 9px !important;
  font-weight: bold;
}



.result-img-blink {
  transition: 0.1s ease-in;
  animation: blink 1s;
  animation-iteration-count: infinite;
}

.open-curtain {
  position: absolute;
  top: 0px;
  width: 100%;
  -webkit-animation: curtain 14.2s !important;
  animation: curtain 14.2s !important;
  z-index: 999;
  height: 105%;
}

@keyframes curtain {
  0% {
    left: -30px;
  }

  50% {
    left: 43%;
  }

  100% {
    left: 100%;
  }
}











@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes blink {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* new */
.curtain {
  width: 100%;
  /* Ensures the component is the full screen width */
  height: 17vw;
  overflow: hidden;
  /* Allows you to slide the panels outside the container without them showing */
}

.curtain__wrapper {
  /*These make sure the curtain covers the whole container*/
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.curtain__panel {
  /*These sets a background color as well as an img to make it look like an actual curtain*/
  background-image: url("../../../../public/images/yuki/curtains.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  /* Each panel takes up half the container */
  height: 100%;
  /* Used for demo purposes */
  float: left;
  /* Makes sure panels are side-by-side */
  position: relative;
  /* Needed to define the z-index */
  z-index: 2;
  /* Places the panels in front of the prize */
  transition: all 2.5s ease-out;
  /* Animates the sliding transition */
}

.curtain__panel--left {
  /* Slides the panel to the left out of the container */
  transform: translateX(-100%);
}

.curtain__panel--right {
  /* Slides the panel to the right out of the container */
  transform: translateX(100%);
}
@media (min-width:760px) {
  .curtain__prize {
    height: 97.3%;
  }
  .lotto_prize{
    overflow: auto;
  }
}
@media (max-width:759px) {
  .curtain__prize {
    height: 93%;
  }
  .lotto_prize{
    height: fit-content !important;
    overflow: hidden;
  }
}
.curtain__prize {
  /*This sets the background color and an image (purposesly small to not take up the whole background).  The image is centered and not repeated so that it looks like an img element was used, but is not*/
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  /* Forces the prize position into the container start */
  z-index: 1;
  /* Places the prize behind the panels, which are z-index 2 */
  width: 98%;
  /*Makes sure that it fills the whole container*/


  h1 {
    /*Makes the text white and centers it in the container*/
    color: white;
    text-align: center;
  }
}

input[type=checkbox] {
  position: absolute;
  /* Force the checkbox at the start of the container */
  cursor: pointer;
  /* Indicate the curtain is clickable */
  width: 88%;
  /* The checkbox is as wide as the component */
  height: 88%;
  /* The checkbox is as tall as the component */
  z-index: 100;
  /* Make sure the checkbox is on top of everything else */
  opacity: 0;
  /* Hide the checkbox */
}

/* When the checkbox is checked... */
/* Slide the first panel in */
input[type=checkbox]:checked~div.curtain__panel--left {
  transform: translateX(0);
}

/* Slide the second panel in */
input[type=checkbox]:checked~div.curtain__panel--right {
  transform: translateX(0);
}

.checkbox-style {
  position: absolute;
  top: 1.5px;
  right: 0px;
  z-index: 9;
}

input[type=checkbox] {
  transform: scale(1.2);
  margin-right: 2px;
}

.v-dialog>.v-card>.v-card__text {
  padding-bottom: 10px !important;
}

.v-btn:not(.v-btn--round).v-size--x-large {
  height: 50px;
  min-width: 60px;
  padding: 0 0px;
}

.v-btn__content {
  height: 100% !important;
}

.checkbox-style1 {
  position: absolute;
  top: 1.5px;
  right: -2px;
  margin-top: -10px
}



@media(max-width:760px) {
  .yk-submit-btn {
    height: 49px !important;
    background-color: #0c4588;
    width: 100%;
    margin-top: -1px;
  }
  .yuki-input-betting {
    width: 80%;
    height: 50px;
    margin-top: -5px;
    border: 3px solid #0c4588;
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: 1px;
  }
  
  .yuki-clear-betting {
    width: 20%;
    text-align: center;
    padding-top: 7px;
    padding-right: 5px;
    border: 3px solid #0c4588;
    border-left: 0px;
    height: 50px;
    margin-top: -5px;
  }
  .img-btn{
    padding : 1px 0px 0px 0px;
    margin:2px!important;
    
  }
  .animal-payout {
    color:black!important;
    font-size:.75rem!important;
  }
  .limit-value{
    font-size:.8rem
  }
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.2rem;
    margin: 0px !important;

  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-coin-cover {
    width: 100%
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:760px) {
  .yk-submit-btn {
    height: 79px !important;
    background-color: #0c4588;
    width: 100%;
    margin-top: -1px;
  }
  .yuki-input-betting {
    width: 80%;
    height: 75px;
    margin-top: -5px;
    border: 3px solid #0c4588;
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: 1px;
  }
  
  .yuki-clear-betting {
    width: 20%;
    text-align: center;
    padding-top: 18px;
    padding-right: 5px;
    border: 3px solid #0c4588;
    border-left: 0px;
    height: 75px;
    margin-top: -5px;
  }
  .yk-submit-btn {
    height: 75px !important;
    background-color: #0c4588;
    width: 100%;
  }
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.25rem;
    margin: 0px !important;

  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
  .limit-value{
    font-size:.95rem
  }
}
@media(max-width:1440px) {
  .each-button-height {
    height: 93px !important;
  }
}
@media(min-width:1440px) {
  .each-button-height {
    height: 96.2px !important;
  }
}
@media(min-width:1900px) {
  .each-button-height {
    height: 98px!important;
  }
}


@media(min-width:960px) {
  .img-btn{
    margin-left:15px;
    margin-right:15px;
    margin-top:5px;
  }
  .animal-payout {
    color:black!important;
    font-size:1rem;
  }
  .yuki-order-1{
    order :1
  }
  .yuki-order-2{
    order :2
  }
  .hide-large-screen{
    display:none
  }
  .betting-name {
    text-shadow: 1px 1px black;
    font-size: 1.6vw;
    font-weight: bold;
  }
  .limit-value{
    font-size:.8rem
  }
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.25rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
  .curtain {
    width: 100%;
    /* Ensures the component is the full screen width */
    height: 17vw;
    overflow: hidden;
    /* Allows you to slide the panels outside the container without them showing */
  }
  .animal-name{
    font-size:1vw; margin-top:-4px
  }
  
.d-result-animal {
  width: 12vw;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50%;
  z-index: 99;
  position: inherit;
}
.yuki-coins{
  width:12.4%!important;
  
  max-width:12.4%;
   flex: 0 0 12.4%!important;
}

}
@media(max-width:960px) {
  .img-btn{
    margin-left:15px;
    margin-right:15px;
    margin-top:5px;
  }
  .animal-payout {
    color:black!important;
    font-size:1rem;
  }
  .yuki-order-1{
    order :2
  }
  .yuki-order-2{
    order :1
  }
  .hide-small-screen{
    display:none
  }
  .yuki-coins{
    text-align: center;
    margin:0x 10px!important;
    width:20.4%!important; 
    max-width:20.4%!important; flex: 0 0 20.4%!important;
  }
  .each-button-height {
    height: 70px !important;
  }
  .betting-name {
    text-shadow: 1px 1px black;
    font-size: 4.2vw;
    font-weight: bold;
    line-height: 27px;
  }
  .curtain {
    width: 100%;
    /* Ensures the component is the full screen width */
    height: 27vh;
    overflow: hidden;
    /* Allows you to slide the panels outside the container without them showing */
  }
  .animal-name{
    font-size:1.1vh; margin-top:-2px
  }
  
.d-result-animal {
  width: 45vw;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50%;
  z-index: 99;
  position: inherit;
}
}

@media(min-width:1280px) {
  .limit-value{
    font-size:.9rem
  }
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.3rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .money-in-coin-position {
    margin-top: -52px;
    margin-bottom: 25px;
    z-index: 100;
    font-weight: 900;
    font-size: 16px;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}
@media(min-width:1600px) {
  .limit-value{
    font-size:1rem
  }
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.3rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .money-in-coin-position {
    margin-top: -52px;
    margin-bottom: 25px;
    z-index: 100;
    font-weight: 900;
    font-size: 16px;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1.4rem;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

.on-active-btn {
  color:goldenrod!important;
  text-shadow: 1px 1px black;
  /* background-color: #fec84e; */
  background-image: linear-gradient(315deg, #72dfdf 0%, #8ceeee2c 100%) !important;
}
.on-active-btn > span > span {
  color:gold!important;
  text-shadow: 1px 1px black;
}
.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.hold-coin {
  position: sticky;
  left: 0px;
  z-index: 100;
  background: #013366;
  font-size: bolder;
  border-right: 2px dashed white;
}

.coin-container {
  overflow: auto;
  flex-wrap: nowrap;
  width: 100%;
}

.coin-container:hover {
  cursor: pointer;
}

.display-money {
  background-color: rgb(255, 255, 255, .9);
  height: 40px;
  border-radius: 5px;
  margin: 5px 0px 7px 0px;
  color: black;
  font-weight: bolder;
  text-shadow: 1px 0.2px #406386;
  font-size: 1.3rem;
  padding-top: 5px;
}


.yk-bet-input-number span .v-icon.v-icon {
  line-height: 45px;
  color: red !important;
}

/*.bet-Box{
  background: none;
}*/
.btn-top-img {
  width: 70%;
  position: absolute;
  top: 2%;
  left: 0%;
}

.bet-Box {
  background: none;
}

.bet-Box-blue {
  background: linear-gradient(237deg, transparent, transparent 93.71%),
    linear-gradient(137deg, transparent, transparent 92.71%),
    linear-gradient(316deg, rgba(0, 0, 255, .6) 0%, rgba(0, 0, 255, .0) 50.71%),
    linear-gradient(316deg, rgba(0, 0, 255, .7) 0%, rgba(0, 0, 255, .0) 55.71%),
    linear-gradient(316deg, rgba(0, 0, 255, .8) 30%, rgba(0, 0, 255, .0) 30.71%);
  height: 100%;
  width: 100%;
  position: relative;
}

.bet-Box-red {
  background: linear-gradient(237deg, transparent, transparent 93.71%),
    linear-gradient(137deg, transparent, transparent 92.71%),
    linear-gradient(316deg, rgba(255, 0, 0, 0.6) 0%, rgba(0, 0, 255, .0) 50.71%),
    linear-gradient(316deg, rgba(255, 0, 0, 0.7) 0%, rgba(0, 0, 255, .0) 55.71%),
    linear-gradient(316deg, rgba(255, 0, 0, 0.8) 30%, rgba(0, 0, 255, .0) 30.71%);
  height: 100%;
  width: 100%;
  position: relative;
}

.my-edit-num {

  position: absolute;
  bottom: 12%;
  right: 4%;
  color: white;
  font-weight: 800;
}

.bottom-payout {
  color: white;
}

.v-chip,
.v-chip__content,
.v-list-item__title,
span {

  font-weight: 700;
}

.yuki-span-bet-name {
  position: absolute;
  top: 62%;
  left: 12%;
  font-size: 1rem;
  font-weight: 900;
}

.yuki-span-bet-payout {
  position: absolute;
  top: 25%;
  left: 66%;
  font-size: 1rem;
  font-weight: 900;
}

.yuki-btn-bottom-text-blue {
  color: blue;
}

.yuki-btn-bottom-text-red {
  color: red;
}

.curtain-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.curtains {
  width: 100%;
  height: 355px;
  z-index: 0 !important;
  margin-top: -119px !important;
  margin-bottom: 20px;
  object-fit: contain;
  margin-left: 0px;
}

.curtains-header {
  width: 100%;
  height: 50px !important;
  z-index: 1 !important;
}

.result-img {
  width: 33%;
  height: auto;
  position: absolute !important;
  top: 24%;
  right: 34%;
}

@keyframes blink {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.result-img-blink {
  transition: 0.3s ease-in;
  animation: blink 1s;
  animation-iteration-count: infinite;
}

.open-curtain {
  position: absolute;
  width: 100%;
  -webkit-animation: curtain 14.2s !important;
  animation: curtain 14.2s !important;
  z-index: 1;
  padding-left: 20px;
}

@keyframes curtain {
  0% {
    left: -30px;
  }

  50% {
    left: 43%;
  }

  100% {
    left: 100%;
  }
}

.v-tabs:not(.v-tabs--vertical).v-tabs--right>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next,
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;
}

.classMobile {
  padding: 0 !important;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}


.blink-bonus {
  animation: blink-bonus 1s steps(5, start) infinite;
  -webkit-animation: blink-bonus 1s steps(5, start) infinite;
  animation: blink-bonus 1s infinite;
}

@keyframes blink-bonus {
  0% {
    box-shadow: 2px 2px 2px 2px transparent inset;

  }

  50% {
    box-shadow: 0px 0px 0px 0px transparent inset;
    background: gold;
  }

  100% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }
}
@media(min-width:1920px){

}
</style>
