
const getLastResults = (state) => state.lastResults;
const getCurrentBetting = (state) => state.currentBetting;
const getPreviousBetting = (state) => state.previousBetting;
const getCurrentGame = (state) => state.currentGame;
const getHeadTailType = (state) => state.headTailType;
const getRangeType = (state) => state.rangeType;
const getLastOneResult = (state) => state.lastOneResult;
const getLastTwentyResult = (state) => state.lastTwentyResult;
const getIsBetting = (state) => state.is_Betting;
const getButtonMoney = (state) => state.buttonMoney;
const getLastFiveResults = (state) => state.lastFiveResults;
const getTypeMaster = (state) => state.typeMaster;
//Button betting
const getFirstSixBtn = (state) => state.firstSixBtn;
const getSixToTwelve = (state) => state.sixToTwelve;
const getRangeSmall = (state) => state.rangeSmall;
const getRangeBig = (state) => state.rangeBig;
const getSmallBig = (state) => state.smallBig;
const getBlueRed = (state) => state.blueRed;
const getOddPair = (state) => state.oddPair;
const getTotalInvoice = (state) => state.totalInvoice;
const getLimit = (state) => state.limitYukiBetting;
const getSelectBtn = (state) => state.selectBtn;

export default {
    getSelectBtn,
    getLimit,
    getLastResults,
    getCurrentBetting,
    getPreviousBetting,
    getCurrentGame,
    getHeadTailType,
    getRangeType,
    getTotalInvoice,
    getLastOneResult,
    getLastTwentyResult,
    getIsBetting,
    getButtonMoney,
    getLastFiveResults,
    getTypeMaster,
    getSixToTwelve,
    getFirstSixBtn,
    getRangeSmall,
    getRangeBig,
    getSmallBig,
    getOddPair,
    getBlueRed
};
