export default {
    permission: 'การอนุญาต',
    role: 'บทบาท',
    description: 'คำอธิบาย',
    menu_name: 'เมนู ชื่อ',
    icon: 'ไอคอน',
    order: 'คำสั่ง',
    active: 'คล่องแคล่ว',
    actions: 'การกระทำ',
    balance: 'สมดุล',
    created_at: 'วันที่สร้าง',
    bank_account: 'บัญชีธนาคาร',
    user_name: 'ชื่อผู้ใช้',
    deposit_withdraw: 'ฝาก / ถอนเงิน',
    no :"ตัวเลข",
    game:"เกม",
    date:"วันที่",
    result:"ผลลัพธ์",
    detail:"รายละเอียด",
    headtail:"หัวหาง",
    range:"พิสัย",
    id:"รหัส",
    account:"บัญชี",
    bet:"เดิมพัน",
    type:"พิมพ์",
    payout:"การจ่ายเงิน",
    winLose:"แพ้ชนะ",
    oldBalance:"ยอดคงเหลือเก่า",
    newBalance:"สมดุลใหม่",
    amount: "จำนวน",
    amount_betting:"จำนวนเงินเดิมพัน", 
    see_detail:"ดูรายละเอียด",
    amountw: "จำนวนเงินที่ถอนออก",
    small_large: "เล็ก / ใหญ่",
    invoice_no: "ใบแจ้งหนี้ เลขที่",
    turn_over: "เทิร์น โอเวอร์",
    total_cast:"นักแสดงทั้งหมด",
    bettingType:'ประเภทการเดิมพัน',
    post:'ช่อง',
    game_no:'หมายเลขเกม',
    group: 'กลุ่ม',
    action:'การกระทำ',
    old_balance:'ยอดคงเหลือเก่า',
    new_balance:'สมดุลใหม่',
    invoice_id:"เลขใบสั่งของ",

};
