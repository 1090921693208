import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    darkMode: true,
    balance: 0,
    userInfo: {
        currencySymbol: "",
        balance: 0
    },
    channelId: 0,
    channelList: [],
    isSoundOn: true,
    bettingAmount:0,
    isAudioPlay: false
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
