<template>
    <v-col cols="12 px-1 pt-0 mt-2 pb-0" >
        <v-row class="ma-0">
            <v-col cols="12" class="pa-0">
                <v-row class="ma-0"> 
                <input placeholder="0" type="number" v-model="getBettingAmount" pattern="[0-9]*" min="1"
                    @input="(event) => inputAmount(event.target.value)"
                    onlynumber="true" class="yuki-input-betting" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                <span class="yuki-clear-betting"><v-icon @click="_clearMoney" size="35" >mdi-history</v-icon></span>
                </v-row>
            </v-col>
        </v-row>
    </v-col>
</template>
  
  
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Mixin from "../../../../../mixin/index.js";
export default {
    components: {
        // Tabs,
    },
    data() {
        return {
            mixins: [Mixin],
            loading : false
        }
    },
    computed: {

            ...mapGetters("$_modules", {
                userInfo: "getUserInfo",
                getBettingAmount: "getBettingAmount",
            }),
        ...mapGetters("$_yuki160s", {
            currentGame: "getCurrentGame",
            getLimit: "getLimit",
            getSelectBtn:"getSelectBtn"
        }),
    },
    methods:{
        inputAmount(amount) {
            this.UPDATE_UPDATE_BETTING_AMOUNT(amount);
            },
        _clearMoney() {
            this.CLEAR_BETTING_AMOUNT();
        },
       
        
        
        ...mapMutations("$_modules", [
            "UPDATE_UPDATE_BETTING_AMOUNT", "CLEAR_BETTING_AMOUNT"
        ]),

            ...mapActions("$_modules", [
        "fetchUserInfo"
        ]),
        } 
};
</script>