<template>
    <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0">
        <ChannelInfo
            :passChannel="passChannel"
        />
        <div>
            <div class="g-summary">
                <div class="sides-wrapper gp-panel-bg rounded">
                    <div class="d-flex sides bet-btn-container">
                        <div v-if="meronButton" class="side-section meron-section pb-1 side-1"
                            :class="[selectedBtnClassMeron ? 'on-active-btn' : '', passSelectedChannel == 19 ? 'meron-section' : 'wala-section ' ]"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:1;' : ''"
                            @click="_allowBtnBet('selectedMeron', meronButton)">

                            <div class=" pt-12 mb-0 ">
                                <span style="font-weight: 600;">{{ passSelectedChannel==19 ? 
                                userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.tiger_amount) : formatBtnCurrency(passCurrentBetting.tiger_amount)
                                :  userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.player_amount) : formatBtnCurrency(passCurrentBetting.player_amount)}}</span>
                                <!--
                                <span style="font-weight:600">
                                    {{
                                        userInfor && userInfor.currency_type == 2
                                        ? formatBtnCurrencyKh(passCurrentBetting.tiger_amount)
                                        : formatBtnCurrency(passCurrentBetting.tiger_amount)
                                    }}
                                </span>
                                -->
                                
                                <div class="side-title pt-1">
                                    <div class="truncate font-weight-bold">{{ meronButton ? $t('title.' + meronButton.name)
                                        : ''
                                    }}</div>
                                </div>
                                <div class="odds">{{ passBettingTypeMaster ?passBettingTypeMaster[1].payout_display : 'x.xx' }}</div>
                            </div>
                        </div>
                        <div v-if="tieButton" class="side-section-tie tie-section  mx-2 side-1"
                            :class="selectedBtnClassTie ? 'on-active-btn' : ''" @click="_allowBtnBet('selectedTie')" 
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:1;' : ''">
                
                            <div class="pt-12 mb-0 "><span style="font-weight:600">{{
                                userInfor && userInfor.currency_type == 2
                                ? formatBtnCurrencyKh(passCurrentBetting.tie_amount) :
                                formatBtnCurrency(passCurrentBetting.tie_amount)
                            }}</span>
                            <div class="side-title pt-1">
                                <span class="truncate font-weight-bold">{{ $t('title.draw') }}</span>
                            </div>
                                <div class="odds font-weight-bold">{{ passWeight ? `1: 8` : 'x.xx' }}</div>
           
                            </div>
                        </div>
                        <div
                            v-if="walaButton" 
                            class="side-section pb-1 side-1"
                            :class="[ selectedBtnClassWala ? 'on-active-btn' : '',  passSelectedChannel == 19 ? 'wala-section' : 'meron-section '  ]"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:1;' : ''"
                            @click="_allowBtnBet('selectedWala')">
                            
                            <div class="pt-12 mb-0">
                                <span style="font-weight: 600;">{{ passSelectedChannel==19 ? 
                                userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.dragon_amount) : formatBtnCurrency(passCurrentBetting.dragon_amount)
                                :  userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.banker_amount) : formatBtnCurrency(passCurrentBetting.banker_amount)}}</span>
                                <!--<span style="font-weight:600">{{
                                userInfor && userInfor.currency_type == 2
                                ? formatBtnCurrencyKh(passCurrentBetting.dragon_amount) :
                                formatBtnCurrency(passCurrentBetting.dragon_amount)
                            }}</span>-->
                                
                                <div class="side-title pt-1">
                                    <div class="truncate font-weight-bold ">{{ walaButton ? $t('title.' + walaButton.name) :
                                        ''
                                    }}</div>
                                </div>
                                <div class="odds">{{ passBettingTypeMaster ?passBettingTypeMaster[0].payout_display : 'x.xx' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Group Coin -->
            <CoinButton 
                :passGetButton="passGetButton" 
                :passChannel="passChannel"
                :passPlaceCoin="bettingMoney"
                v-on:handleInputMonay="_inputAmount"/>
                <SubmitBtn  
            :passCheckAllowSubmit="_checkAllowSubmit"
            v-on:handleSubmitBtn="_submitBetting"
            v-on:handleClearBtn="_clearMoney"/>
            <LimitBetting />
        </div>
    </div>
</template>

<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import CoinButton from '../../../../components/CoinBettingButton.vue';
import ChannelInfo from '../../../../components/ChannelInfo.vue';
import LimitBetting from '../../../../components/LimitBetting.vue';
import SubmitBtn from '../../../../components/SubmitBtn.vue';
import { mapGetters, mapMutations } from "vuex";
export default {
    components: { CoinButton, ChannelInfo, LimitBetting, SubmitBtn },
    props: [
        "passChannel",
        "passCurrentBetting",
        'passChannelStatus',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passSelectedChannel',
    ],
    data() {
        return {
            selectedButton: {},
            bettingMoney: 0,
            selectedBtnClassMeron: false,
            selectedBtnClassTie: false,
            selectedBtnClassWala: false,
            selectedCoin: false,
        }
    },
    watch: {
        dialogBetting(newV) {
            !newV ? this.bettingMoney = 0 : '';
        },
    },
    computed: {

        meronButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        walaButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        tieButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        _checkAllowSubmit() {
            if ( this.bettingMoney > 0 && 
                    ( this.selectedBtnClassMeron || this.selectedBtnClassTie || this.selectedBtnClassWala )) return true;
            else  return false;
        },
        ...mapGetters("$_dashboard", {
            dialogBetting: "getDialogBetting",
        }),
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },
    methods: {
        checkSattus() {
            if (this.passChannel.is_open == null) {
                return "Not_Open"
            } else {
                if (this.passChannel.is_open) {
                    return "Open"
                }
                return "Close"
            }
        },
        checkStatus() {
            if (this.passChannel.is_open == null) {
                return "text-warning"
            } else {
                if (this.passChannel.is_open) {
                    return "text-success"
                }
                return "text-danger"
            }
        },
        _detectCurrentMoneyCoinMeron(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorMeron(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'black';
                case money < 50:
                    return 'black';
                case money < 100:
                    return 'black';
                case money < 99999999:
                    return 'black';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyCoinWala(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorWala(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'black';
                case money < 50:
                    return 'black';
                case money < 100:
                    return 'black';
                case money < 99999999:
                    return 'black';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyCoinTie(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorTie(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'black';
                case money < 50:
                    return 'black';
                case money < 100:
                    return 'black';
                case money < 99999999:
                    return 'black';
                default:
                    return ''
            }
        },


        _inputAmount(money) {
                this.selectedCoin = true
                this.bettingMoney = parseInt(this.bettingMoney);
                this.bettingMoney += parseInt(money)
        },
        _clearMoney() {
            this.bettingMoney = 0;
            this.selectedBtnClassMeron = false;
            this.selectedBtnClassTie = false;
            this.selectedBtnClassWala = false;
            this.selectedCoin = false;
        },
        _submitBetting() {
            let submitBettingData = {
                type_id: this.selectedButton._id,
                amount: this.bettingMoney
            };
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();
        },
        _allowBtnBet(selectedBtn) {
            if (!this.selectedCoin ) {
                this.$toastr.e(this.$t('title.selectCoin'));
                return false;
            }
            switch (selectedBtn) {
                case 'selectedMeron':
                    this.selectedButton = this.meronButton;
                    this.selectedBtnClassMeron = true;
                    this.selectedBtnClassTie = false;
                    this.selectedBtnClassWala = false;
                  
                    break;
                case 'selectedTie':
                    this.selectedButton = this.tieButton;
                    this.selectedBtnClassTie = true;
                    this.selectedBtnClassMeron = false;
                    this.selectedBtnClassWala = false;
                  
                    break;
                case 'selectedWala':
                    this.selectedButton = this.walaButton;
                    this.selectedBtnClassWala = true;
                    this.selectedBtnClassTie = false;
                    this.selectedBtnClassMeron = false;
                  
                    break;
                default :
                    break;
            }
        },
        formatBtnCurrencyKh(amount) {
            switch (true) {
                case (amount >= 1000 && amount <= 9999):
                    return amount / 1000 + "ពាន់";
                case (amount >= 10000 && amount <= 999999):
                    return amount / 10000 + "មុឺន";
                case (amount >= 1000000 && amount <= 99999999999):
                    return amount / 1000000 + "លាន";
                default:
                    return amount
            }
        },
        formatBtnCurrency(amount) {
            switch (true) {
                case (amount >= 1000 && amount <= 9999):
                    return amount / 1000 + "K";
                default:
                    return amount
            }
        },
        formatCoinCurrency(amount) {
            switch (amount) {
                case 5000:
                    return '5ពាន់';
                case 10000:
                    return '1មុឺន';
                case 20000:
                    return '2មុឺន';
                case 50000:
                    return '5មុឺន';
                case 100000:
                    return '10មុឺន';
                case 200000:
                    return '20មុឺន';
                default:
                    return amount;
            }

        },
        ...mapMutations("$_dashboard", [
            "BETTING_DIALOG"
        ]),
    }
}
</script>
<style scoped>

.truncate{
    font-size:1.7rem;
    font-weight: 700;
    text-shadow: 3px 0px 7px rgba(245, 243, 230, 0.1), -3px 0px 7px rgba(252, 246, 229, 0.1), 0px 4px 7px rgba(221, 216, 199, 0.1);
}
@media(min-width:960px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 185px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }

    .betting-coin-meron {
        width: 80px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-wala {
        width: 80px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        justify-content: center;
        width: 150px;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        width: 150px;
        color: white;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

    .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
        /* transform: scale(1.005); */
    
    }

    .side-section:hover {
        cursor: pointer;
    }



    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        justify-content: center;
        width: 150px;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        width: 150px;
        color: white;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

    .on-active-btn {
        border: 4px solid gold;
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
    }

    .side-section:hover {
        cursor: pointer;
    }


    .money-in-coin-position {
        margin-top: -50.5px;
        margin-bottom: 25px;
        margin-left: -2px;
        z-index: 100;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        font-size: 14px;
    }

}

@media(min-width:1280px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 200px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }

    .betting-coin-meron {
        width: 80px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-wala {
        width: 80px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        justify-content: center;
        width: 150px;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        width: 150px;
        color: white;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

    .on-active-btn {
        border: 4px solid gold;
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        justify-content: center;
        width: 150px;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        width: 150px;
        color: white;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

    .on-active-btn {
        border: 4px solid gold;
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
    }

    .side-section:hover {
        cursor: pointer;
    }

    .money-in-coin-position {
        margin-top: -52px;
        margin-bottom: 25px;
        z-index: 100;
        font-weight: 900;
        font-size: 16px;
    }



    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 200px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }
}
.bet-btn-container{
    padding-right: 13px!important;
    padding-left: 10px!important;
}
</style>