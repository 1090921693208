<template>
  <!-- eslint-disable -->
  <div>
    <v-btn 
      @click="print(todayReport._id)"
      x-small color="primary"
      id="print-button"
    >
    Print
    </v-btn >
    <table :id="`printMe${todayReport._id}`" hidden>
      <tbody>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            wpc168.live
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            Bet ID: {{ todayReport._id }}
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td style="padding: 5px; text-align: left">Fight No:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ todayReport.fight_no ? todayReport.fight_no : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Bet Type:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ todayReport.betting ? todayReport.betting : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Date:</td>
                  <td style="padding: 5px; text-align: left">
                    {{todayReport.time}}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Bet Point:</td>
                  <td style="padding: 5px; text-align: left" v-if="todayReport.result_name">
                    {{ todayReport ? $t('title.'+todayReport.result_name)  : ''}}
                  </td>
               
                  <td style="padding: 5px; text-align: left" v-else-if="!todayReport.result_name">
                         {{ todayReport && todayReport.result1_name ? _getResultClass(todayReport.result1_name).n+", " :"" }}
                                            {{ todayReport &&  todayReport.result1_name ? _getResultClass(todayReport.result2_name).n+", " :"" }}
                                            {{ todayReport &&  todayReport.result1_name ? _getResultClass(todayReport.result3_name).n :"" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Win/Lose</td>
                  <td style="padding: 5px; text-align: left">
                    <span>{{todayReport.is_win ? "WIN" : "LOSE"}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ["todayReport"],
  methods: {
    async print(id) {
      // Pass the element id here
      await this.$htmlToPaper(`printMe${id}`);
    },
    _getResultClass(x) {
            switch (x) {
                case 'Banker 1':
                    return { c: 'han2-r-td-banker', n: 'B1', f: 'B', cf: 'han2-r-td-banker-f' }
                case 'Banker 2':
                    return { c: 'han2-r-td-banker', n: 'B2', f: 'B', cf: 'han2-r-td-banker-f' }
                case 'Banker 3':
                    return { c: 'han2-r-td-banker', n: 'B3', f: 'B', cf: 'han2-r-td-banker-f' }
                case 'Player 1':
                    return { c: 'han2-r-td-player', n: 'P1', f: 'P', cf: 'han2-r-td-player-f' }
                case 'Player 2':
                    return { c: 'han2-r-td-player', n: 'P2', f: 'P', cf: 'han2-r-td-player-f' }
                case 'Player 3':
                    return { c: 'han2-r-td-player', n: 'P3', f: 'P', cf: 'han2-r-td-player-f' }
                case 'CANCEL':
                    return { c: 'han2-r-td-cancel', n: 'C', f: 'C', cf: 'han2-r-td-cancel-f' }
                case 'Tie':
                    return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
                case 'Tie 1':
                    return { c: 'han2-r-td-tie', n: 'T1', f: 'T', cf: 'han2-r-td-tie-f' }
                case 'Tie 2':
                    return { c: 'han2-r-td-tie', n: 'T2', f: 'T', cf: 'han2-r-td-tie-f' }
                case 'Tie 3':
                    return { c: 'han2-r-td-tie', n: 'T3', f: 'T', cf: 'han2-r-td-tie-f' }
                default:
                    return ''
            }
        },
  },
};
</script>