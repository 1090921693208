<template>
  <div>
    <div class="lotto-nav-head">
      <Tabs/>
    </div>
    <v-row no-gutters class="ma-0">
      <v-col cols="12" sm="12" md="5" style="height:100%">
       <GameInfo :passClientTime="passClientTime"></GameInfo>
       <GameResult :passClientTime="passClientTime" :passClientResult="passClientResult" :passClientRandom="passClientRandom" :passRandomNumber="passRandomNumber"></GameResult>
       <SubmitButton :passClientTime="passClientTime" class="hide-small-screen"></SubmitButton>
      </v-col>
      <v-col cols="12" sm="12" md="7" style="height:100%" >
        <GameBettingButton :passClientTime="passClientTime"></GameBettingButton>
        <SubmitButton :passClientTime="passClientTime" class="hide-large-screen"></SubmitButton>
      </v-col>
    </v-row>
    <CoinButton :passPlaceCoin="passPlaceCoin"></CoinButton>
    </div>
</template>


<script>
import Tabs from "../../../../components/machine/Tabs.vue";
import GameInfo from "./GameContentComponent/Game_info.vue";
import GameResult from "./GameContentComponent/Game_Result.vue";
import GameBettingButton from "./GameContentComponent/Game_betting_button.vue";
import SubmitButton from "./GameContentComponent/SubmitButton.vue";
import CoinButton from "./GameContentComponent/CoinButton.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    GameInfo,
    GameBettingButton,
    GameResult,
    SubmitButton,
    CoinButton,
    Tabs
  },
  props: [
    'passGetButton',
    'passChannel',
    'passRandomNumber',
    'passClientRandom',
    'passClientResult',
    "passClientTime",
  ],
  data() {
    return {
      selectedButton: {},
      betType: 0,
      selectBetType: [],
      checkedOneSix: [],
      checkedSevenUp: [],
      checkedRangeSmall: [],
      checkedRangeBig: [],
      checkedSmallBig: [],
      checkedOddPair: [],
      checkedBlueRed: [],
      totalMoney: 0,
      rgetLimit: [],
      amount: 0,
      errorMessage: null,
      isBetting: false,
    }
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapGetters("$_yuki160s", {
      currentGame: "getCurrentGame",
      is_Betting: "getIsBetting",
      passPlaceCoin: "getButtonMoney",
      getLastOneResult: "getLastOneResult",
      passSixBtn: "getFirstSixBtn",
      passSixToTwelve: "getSixToTwelve",
      passRangeSmall: "getRangeSmall",
      passRangeBig: "getRangeBig",
      passSmallBig: "getSmallBig",
      passOddPair: "getOddPair",
      passBlueRed: "getBlueRed"
    }),
  },
  methods: {
    _g_translate(x) {
      switch (x) {
        case "ជូត":
          return "title.yuki_wipe";
        case "ឆ្លូវ":
          return "title.yuki_street";
        case "ខាល":
          return "title.yuki_karl";
        case "ថោះ":
          return "title.yuki_thao";
        case "រោង":
          return "title.yuki_factory";
        case "ម្សាញ់":
          return "title.yuki_net";
        case "ស្តើង":
          return "title.yuki_thin";
        case "ក្រាស់":
          return "title.yuki_thick";
        case "មមី":
          return "title.yuki_mimi";
          case "មមែ":
          return "title.yuki_mom";
        case "វក":
          return "title.yuki_vk";
        case "រកា":
          return "title.yuki_roka";
        case "កុរ":
          return "title.yuki_kor";
        case "សេស":
          return "title.yuki_odd";
        case "គូ":
          return "title.yuki_pair";
        case "ក្រហម":
          return "title.yuki_red";
        case "ខៀវ":
          return "title.yuki_blue";
        case "ច":
          return "title.yuki_dog";
        default:
          return 'yuki_null'
      }
    },
    _selected(id, type) {
      if (type == 1) {
        if (this.checkedOneSix.includes(id)) {
          let i = this.checkedOneSix.map(item => item).indexOf(id) // find index of your object
          this.checkedOneSix.splice(i, 1)
        } else {
          this.checkedOneSix.push(id)
        }
      } else if (type == 2) {
        if (this.checkedSevenUp.includes(id)) {
          let i = this.checkedSevenUp.map(item => item).indexOf(id) // find index of your object
          this.checkedSevenUp.splice(i, 1)
        } else {
          this.checkedSevenUp.push(id)
        }
      } else if (type == 3) {
        if (this.checkedRangeSmall.includes(id)) {
          let i = this.checkedRangeSmall.map(item => item).indexOf(id) // find index of your object
          this.checkedRangeSmall.splice(i, 1)
        } else {
          this.checkedRangeSmall.push(id)
        }
      } else if (type == 4) {
        if (this.checkedRangeBig.includes(id)) {
          let i = this.checkedRangeBig.map(item => item).indexOf(id) // find index of your object
          this.checkedRangeBig.splice(i, 1)
        } else {
          this.checkedRangeBig.push(id)
        }
      } else if (type == 5) {
        if (this.checkedSmallBig.includes(id)) {
          let i = this.checkedSmallBig.map(item => item).indexOf(id) // find index of your object
          this.checkedSmallBig.splice(i, 1)
        } else {
          this.checkedSmallBig.push(id)
        }
      } else if (type == 6) {
        if (this.checkedOddPair.includes(id)) {
          let i = this.checkedOddPair.map(item => item).indexOf(id) // find index of your object
          this.checkedOddPair.splice(i, 1)
        } else {
          this.checkedOddPair.push(id)
        }
      } else if (type == 7) {
        if (this.checkedBlueRed.includes(id)) {
          let i = this.checkedBlueRed.map(item => item).indexOf(id) // find index of your object
          this.checkedBlueRed.splice(i, 1)
        } else {
          this.checkedBlueRed.push(id)
        }
      }
      this.calculateMoney()
    },
    getAmount(money) {
      if (this.amount) this.amount = parseInt(this.amount) + parseInt(money);
      else this.amount = parseInt(money);
      if (this.checkedOneSix.length <= 0 &&
        this.checkedSevenUp.length <= 0 &&
        this.checkedRangeSmall.length <= 0 &&
        this.checkedRangeBig.length <= 0 &&
        this.checkedSmallBig.length <= 0 &&
        this.checkedOddPair.length <= 0 &&
        this.checkedBlueRed.length <= 0) {
        this.totalMoney = this.amount
        // console.log(`checkboxbtn: ${this.totalMoney}`);
      } else {
        this.calculateMoney()
      }
      this.errorMessage = null;
    },
    calculateMoney() {
      this.$nextTick(() => {
        if (this.amount) {
          this.totalMoney = 0
          if (this.checkedOneSix.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedOneSix.length)
          }
          if (this.checkedSevenUp.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedSevenUp.length)
          }
          if (this.checkedRangeSmall.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedRangeSmall.length)
          }
          if (this.checkedRangeBig.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedRangeBig.length)
          }
          if (this.checkedSmallBig.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedSmallBig.length)
          }
          if (this.checkedOddPair.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedOddPair.length)
          }
          if (this.checkedBlueRed.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedBlueRed.length)
          }
          this.UPDATE_TOTAL_INVOICE(this.totalMoney);
          // console.log(`checkbox: ${this.totalMoney}`);
        } else {
          this.totalMoney = 0;
          this.UPDATE_TOTAL_INVOICE(this.totalMoney);
          // console.log(`checkbox unchecked: ${this.totalMoney}`);
        }
      })
    },
    handleClear() {
      this.amount = 0;
      this.totalMoney = 0;
      this.UPDATE_TOTAL_INVOICE(this.totalMoney);
      //this.amount = null;
      this.errorMessage = null;
    },
    async handleOk() {
      const { amount, isBetting, userInfor } = this;
      if (isBetting) return;
      this.isBetting = true;
      // console.log(amount);
      if (!amount || amount < 1) {
        this.errorMessage = "Please enter your bet amount!";
        this.$toastr.e(`${this.errorMessage}`)
        // console.log(this.errorMessage);
        this.isBetting = false;
        return;
      }
      if (userInfor.balance <= amount) {
        this.errorMessage = "Not Enough Balance";
        this.$toastr.e(`${this.errorMessage}`)
        // console.log(this.errorMessage);
        this.isBetting = false;
        return;
      }
      if (amount < userInfor.amount_min || amount > userInfor.amount_max) {
        this.errorMessage = `Bet amount must between bet limit`;
        this.$toastr.e(`${this.errorMessage}`)
        // console.log(this.errorMessage);
        this.isBetting = false;
        return;
      }
      if (this.checkedOneSix.length <= 0 &&
        this.checkedSevenUp.length <= 0 &&
        this.checkedRangeSmall.length <= 0 &&
        this.checkedRangeBig.length <= 0 &&
        this.checkedSmallBig.length <= 0 &&
        this.checkedOddPair.length <= 0 &&
        this.checkedBlueRed.length <= 0) {
        this.errorMessage = `Please select your bet!`;
        this.$toastr.e(`${this.errorMessage}`)
        this.isBetting = false;
        return;
      }
      try {
        let betData = [];
        if (this.checkedOneSix.length > 0) {
          this.checkedOneSix.forEach((yuki_id) => {
            betData.push({ yuki_id, amount })
          })
        }
        if (this.checkedSevenUp.length > 0) {
          this.checkedSevenUp.forEach((yuki_id) => {
            betData.push({ yuki_id, amount })
          })
        }
        if (this.checkedRangeSmall.length > 0) {
          this.checkedRangeSmall.forEach((yuki_id) => {
            betData.push({ yuki_id, amount })
          })
        }
        if (this.checkedRangeBig.length > 0) {
          this.checkedRangeBig.forEach((yuki_id) => {
            betData.push({ yuki_id, amount })
          })
        }
        if (this.checkedSmallBig.length > 0) {
          this.checkedSmallBig.forEach((yuki_id) => {
            betData.push({ yuki_id, amount })
          })
        }
        if (this.checkedOddPair.length > 0) {
          this.checkedOddPair.forEach((yuki_id) => {
            betData.push({ yuki_id, amount })
          })
        }
        if (this.checkedBlueRed.length > 0) {
          this.checkedBlueRed.forEach((yuki_id) => {
            betData.push({ yuki_id, amount })
          })
        }
        // console.log(betData);
        const response = await this.createBet(betData);
        if (response.data.code) {
          this.amount = 0;
          this.UPDATE_TOTAL_INVOICE(0);
          this.fetchUserInfo();
          this.checkedOneSix = [],
            this.checkedSevenUp = [],
            this.checkedRangeSmall = [],
            this.checkedRangeBig = [],
            this.checkedSmallBig = [],
            this.checkedOddPair = [],
            this.checkedBlueRed = [],
            this.fetchCurrentBetting();
          this.$toastr.s(`${response.data.message.descriptions}`)
        } else {
          this.$toastr.e(`${response.data.message.descriptions}`)
          this.error = true;
        }
        this.isBetting = false;
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
  
    _inputAmount(money) {
      this.amount = parseInt(this.amount) + parseInt(money);
      this.calculateMoney()
    },
    formatCoinCurrency(amount) {
      switch (amount) {
        case 1000:
          return '1ព';
        case 5000:
          return '5ព';
        case 10000:
          return '1ម';
        case 20000:
          return '2ម';
        case 50000:
          return '5ម';
        case 100000:
          return '10ម';
        case 200000:
          return '20ម';
        case 500000:
          return '50ម';
        case 1000000:
          return '1ល';
        case 5000000:
          return '5ល';
        default:
          return amount;
      }
    },
    formatBtnCurrencyKh(amount) {
      switch (true) {
        case (amount >= 1000 && amount <= 9999):
          return amount / 1000 + "ព";
        case (amount >= 10000 && amount <= 999999):
          return amount / 10000 + "ម";
        case (amount >= 1000000 && amount <= 99999999999):
          return amount / 1000000 + "ល";
        default:
          return amount
      }
    },
    formatBtnCurrency(amount) {
      switch (true) {
        case (amount >= 1000 && amount <= 9999):
          return amount / 1000 + "K";
        default:
          return amount
      }
    },
    findImageOfEachYear(yearId) {
      switch (yearId) {
        case 1:
          return "images/1.png";
        case 2:
          return "images/2-1.png";
        case 3:
          return "images/3.png";
        case 4:
          return "images/4.png";
        case 5:
          return "images/5.png";
        case 6:
          return "images/6.png";
        case 7:
          return "images/7.png";
        case 8:
          return "images/8.png";
        case 9:
          return "images/9.png";
        case 10:
          return "images/10.png";
        case 11:
          return "images/11.png";
        case 12:
          return "images/12.png";
        default:
        // code block
      }
    },
    findImageOfNumber(yearId) {
      switch (yearId) {
        case 1:
          return "images/number/1.png";
        case 2:
          return "images/number/2.png";
        case 3:
          return "images/number/3.png";
        case 4:
          return "images/number/4.png";
        case 5:
          return "images/number/5.png";
        case 6:
          return "images/number/6.png";
        case 7:
          return "images/number/7.png";
        case 8:
          return "images/number/8.png";
        case 9:
          return "images/number/9.png";
        case 10:
          return "images/number/10.png";
        case 11:
          return "images/number/11.png";
        case 12:
          return "images/number/12.png";
        default:
        // code block
      }
    },
    findImageOfEachAnimal(yearId) {
      switch (yearId) {
        case 1:
          return "images/animal/1.png";
        case 2:
          return "images/animal/2.png";
        case 3:
          return "images/animal/3.png";
        case 4:
          return "images/animal/4.png";
        case 5:
          return "images/animal/5.png";
        case 6:
          return "images/animal/6.png";
        case 7:
          return "images/animal/7.png";
        case 8:
          return "images/animal/8.png";
        case 9:
          return "images/animal/9.png";
        case 10:
          return "images/animal/10.png";
        case 11:
          return "images/animal/11.png";
        case 12:
          return "images/animal/12.png";
        default:
        // code block
      }
    },
    findImageOfEachAnimal2(yearId) {
      switch (yearId) {
        case 1:
          return "images/animal/Asset 1.png";
        case 2:
          return "images/animal/Asset 2.png";
        case 3:
          return "images/animal/Asset 3.png";
        case 4:
          return "images/animal/Asset 4.png";
        case 5:
          return "images/animal/Asset 5.png";
        case 6:
          return "images/animal/Asset 6.png";
        case 7:
          return "images/animal/Asset 7.png";
        case 8:
          return "images/animal/Asset 8.png";
        case 9:
          return "images/animal/Asset 9.png";
        case 10:
          return "images/animal/Asset 10.png";
        case 11:
          return "images/animal/Asset 11.png";
        case 12:
          return "images/animal/Asset 12.png";
        default:
        // code block
      }
    },
    formatMoney(amount) {
      return amount.toLocaleString("en");
    },
    ...mapActions("$_yuki160s", [
      "createBet",
      "fetchCurrentBetting",
    ]),
    ...mapMutations("$_dashboard", [
      "BETTING_DIALOG"
    ]),
    ...mapMutations("$_yuki160s", [
      "BET_TYPE",
      "IS_BETTING",
      "UPDATE_TOTAL_INVOICE"
    ]),
    ...mapActions("$_modules", [
      "fetchUserInfo"
    ]),
  }
};
</script>
<style scoped>
.checkbox-style {
  position: absolute;
  top: 1.5px;
  right: 0px;
  z-index: 9;
}

input[type=checkbox] {
  transform: scale(1.2);
  margin-right: 2px;
}

.v-dialog>.v-card>.v-card__text {
  padding-bottom: 10px !important;
}

.v-btn:not(.v-btn--round).v-size--x-large {
  height: 50px;
  min-width: 60px;
  padding: 0 0px;
}

.v-btn__content {
  height: 100% !important;
}

.checkbox-style1 {
  position: absolute;
  top: 1.5px;
  right: -2px;
  margin-top: -10px
}



@media(max-width:760px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.2rem;
    margin: 0px !important;
    
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-coin-cover {
    width: 100%
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:760px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.25rem;
    margin: 0px !important;

  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:960px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.25rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:1280px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.3rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .money-in-coin-position {
    margin-top: -52px;
    margin-bottom: 25px;
    z-index: 100;
    font-weight: 900;
    font-size: 16px;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 16px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.hold-coin {
  position: sticky;
  left: 0px;
  z-index: 100;
  background: #013366;
  font-size: bolder;
  border-right: 2px dashed white;
}

.coin-container {
  overflow: auto;
  flex-wrap: nowrap;
  width: 100%;
}

.coin-container:hover {
  cursor: pointer;
}

.display-money {
  background-color: rgb(255, 255, 255, .9);
  height: 40px;
  border-radius: 5px;
  margin: 5px 0px 7px 0px;
  color: black;
  font-weight: bolder;
  text-shadow: 1px 0.2px #406386;
  font-size: 1.3rem;
  padding-top: 5px;
}


.yk-bet-input-number span .v-icon.v-icon {
  line-height: 45px;
  color: red !important;
}

/*.bet-Box{
  background: none;
}*/
.btn-top-img {
  width: 70%;
  position: absolute;
  top: 2%;
  left: 0%;
}

.bet-Box {
  background: none;
}

.bet-Box-blue {
  background: linear-gradient(237deg, transparent, transparent 93.71%),
    linear-gradient(137deg, transparent, transparent 92.71%),
    linear-gradient(316deg, rgba(0, 0, 255, .6) 0%, rgba(0, 0, 255, .0) 50.71%),
    linear-gradient(316deg, rgba(0, 0, 255, .7) 0%, rgba(0, 0, 255, .0) 55.71%),
    linear-gradient(316deg, rgba(0, 0, 255, .8) 30%, rgba(0, 0, 255, .0) 30.71%);
  height: 100%;
  width: 100%;
  position: relative;
}

.bet-Box-red {
  background: linear-gradient(237deg, transparent, transparent 93.71%),
    linear-gradient(137deg, transparent, transparent 92.71%),
    linear-gradient(316deg, rgba(255, 0, 0, 0.6) 0%, rgba(0, 0, 255, .0) 50.71%),
    linear-gradient(316deg, rgba(255, 0, 0, 0.7) 0%, rgba(0, 0, 255, .0) 55.71%),
    linear-gradient(316deg, rgba(255, 0, 0, 0.8) 30%, rgba(0, 0, 255, .0) 30.71%);
  height: 100%;
  width: 100%;
  position: relative;
}

.my-edit-num {

  position: absolute;
  bottom: 12%;
  right: 4%;
  color: white;
  font-weight: 800;
}

.bottom-payout {
  color: white;
}

.v-chip,
.v-chip__content,
.v-list-item__title,
span {
  color: white;
  font-weight: 700;
}

.yuki-span-bet-name {
  position: absolute;
  top: 62%;
  left: 12%;
  font-size: 1rem;
  font-weight: 900;
}

.yuki-span-bet-payout {
  position: absolute;
  top: 25%;
  left: 66%;
  font-size: 1rem;
  font-weight: 900;
}

.yuki-btn-bottom-text-blue {
  color: blue;
}

.yuki-btn-bottom-text-red {
  color: red;
}

.curtain-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.curtains {
  width: 100%;
  height: 355px;
  z-index: 0 !important;
  margin-top: -119px !important;
  margin-bottom: 20px;
  object-fit: contain;
  margin-left: 0px;
}

.curtains-header {
  width: 100%;
  height: 50px !important;
  z-index: 1 !important;
}

.result-img {
  width: 33%;
  height: auto;
  position: absolute !important;
  top: 24%;
  right: 34%;
}

@keyframes blink {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.result-img-blink {
  transition: 0.3s ease-in;
  animation: blink 1s;
  animation-iteration-count: infinite;
}

.open-curtain {
  position: absolute;
  width: 100%;
  -webkit-animation: curtain 14.2s !important;
  animation: curtain 14.2s !important;
  z-index: 1;
  padding-left: 20px;
}

@keyframes curtain {
  0% {
    left: -30px;
  }

  50% {
    left: 43%;
  }

  100% {
    left: 100%;
  }
}

.v-tabs:not(.v-tabs--vertical).v-tabs--right>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next,
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;
}

.classMobile {
  padding: 0 !important;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}


.blink-bonus{
  animation: blink-bonus 1s steps(5, start) infinite;
  -webkit-animation: blink-bonus 1s steps(5, start) infinite;
  animation: blink-bonus 1s infinite;
}

@keyframes blink-bonus {
  0% {
    box-shadow: 2px 2px 2px 2px transparent inset;
    
  }

  50% {
    box-shadow: 0px 0px 0px 0px transparent inset;
    background: gold;
  }

  100% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }
}

</style>
