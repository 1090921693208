<template>
  <v-row no-gutters>
      <v-tabs style="color: rgb(255, 0, 0) !important;" fixed-tabs>
          <v-tabs-slider color="#174e87" style="height:3px !important"></v-tabs-slider>
          <v-tab class="g-header">
              <!-- <v-icon left style="color:white"> mdi mdi-cast </v-icon> -->
              <!-- <i class="bi-coin" style="font-size: 1.5rem; color: black; padding: 5px;"></i> -->
              <span style="font-family: 'khmer mef1'; color:black; font-weight:bold">
                  {{ $t("title.result_year") }}
              </span>
          </v-tab>
          <v-tab class="g-header">
              <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
              <!-- <i class="bi-clipboard-data" style="font-size: 1.5rem; color: black; padding: 5px;"></i> -->
              <span style="font-family: 'khmer mef1'; color:black; font-weight:bold">
                  {{ $t("title.result_small_big")}}
              </span>
          </v-tab>
          <v-tab class="g-header">
              <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
              <!-- <i class="bi-clipboard-data" style="font-size: 1.5rem; color: black; padding: 5px;"></i> -->
              <span style="font-family: 'khmer mef1'; color:black; font-weight:bold">
                  {{ $t("title.result_range")}}
              </span>
          </v-tab>
          <v-tab class="g-header">
              <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
              <!-- <i class="bi-clipboard-data" style="font-size: 1.5rem; color: black; padding: 5px;"></i> -->
              <span style="font-family: 'khmer mef1'; color:black; font-weight:bold">
                  {{ $t("title.odd_even")}}
              </span>
          </v-tab>
          <v-tab class="g-header">
              <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
              <!-- <i class="bi-clipboard-data" style="font-size: 1.5rem; color: black; padding: 5px;"></i> -->
              <span style="font-family: 'khmer mef1'; color:black; font-weight:bold">
                  {{ $t("title.blue_red")}}
              </span>
          </v-tab>
      <v-tab-item>
          <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
            <div class="table-responsive pb-3 gp-scroll">
              <table class="trend-table">
                <tbody>
                  <tr v-for="row in rows" :key="row">
                    <td class="result" v-for="col in fixCol" :key="col">
                      <div>
                        <div v-for="(result, i) in orderYearResult" :key="i">
                          <span v-if="col == result.col && row == result.row">
                            <v-img :src="findImageOfEachYear(result.year.year_number)"></v-img>
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </v-tab-item>
      <v-tab-item>
          <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
            <div class="table-responsive pb-3 gp-scroll">
              <table class="trend-table">
                <tbody>
                  <tr v-for="row in rows" :key="row">
                    <td class="result" v-for="col in cols" :key="col">
                      <div>
                        <div v-for="(result, i) in orderBigSmall" :key="i" class="div-result">
                          <span v-if="col == result.col && row == result.row" class="l-result-icon">
                            <v-btn :style="'background:' + result.small_large.color" fab dark>
                              <v-icon>mdi-checkbox-blank-circle</v-icon>
                            </v-btn>
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
            <div class="table-responsive pb-3 gp-scroll">
              <table class="trend-table">
                <tbody>
                  <tr v-for="row in rows" :key="row">
                    <td class="result" v-for="col in cols" :key="col">
                      <div>
                        <div v-for="(result, i) in orderRange" :key="i" class="div-result">
                          <span v-if="col == result.col && row == result.row" class="l-result-icon">
                            <v-btn :style="' background:' + result.range.color" fab dark class="btn-range">
                              <span class="range-name">
                                {{result.range.name}}
                              </span>
                            </v-btn>
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
            <div class="table-responsive pb-3 gp-scroll">
              <table class="trend-table">
                <tbody>
                  <tr v-for="row in rows" :key="row">
                    <td class="result" v-for="col in cols" :key="col">
                      <div>
                        <div v-for="(result, i) in orderOddEven" :key="i" class="div-result">
                          <span v-if="col == result.col && row == result.row" class="l-result-icon">
                            <v-btn :style="' background:' + result.odd_even.color" fab dark class="btn-range">
                              <span class="range-name">
                                {{result.odd_even.name==='សេស' ? 'o' : 'e'}}
                              </span>
                            </v-btn>
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
            <div class="table-responsive pb-3 gp-scroll">
              <table class="trend-table">
                <tbody>
                  <tr v-for="row in rows" :key="row">
                    <td class="result" v-for="col in cols" :key="col">
                      <div>
                        <div v-for="(result, i) in orderRedBlue" :key="i" class="div-result">
                          <span v-if="col == result.col && row == result.row" class="l-result-icon">
                            <v-btn :style="' background:' + result.blue_red.color" fab dark class="btn-range">
                            </v-btn>
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>

      <!-- <v-col cols="12" class="yuki-res-padding-6">

          <div class="yuki-res-head">
              <div class="yuki-res-head-item yuki-r-h-active">
                  <a href="#!">RESULT YEAR</a>
              </div>
              <div class="yuki-res-head-item">
                  <a href="#!">RESULT SMALL/BIG</a>
              </div>
              <div class="yuki-res-head-item">
                  <a href="#!">RESULT RANGE</a>
              </div>
              <div class="yuki-res-head-item">
                  <a href="#!">ODD / EVEN</a>
              </div>
              <div class="yuki-res-head-item">
                  <a href="#!">BLUE / RED</a>
              </div>
              
          </div>

          <div class="yuki-res-body">

              <div class="yuki-res-body-row">
                  <div class="yuki-res-body-col">
                      <div class="yuki-res-body-col-item" v-for="col in 6" :key="col"><img src="images/yuki/rooster.png" /></div>
                     
                  </div>
              </div>
              <div class="yuki-res-body-row">
                  <div class="yuki-res-body-col">

                  </div>
              </div>
              <div class="yuki-res-body-row">
                  <div class="yuki-res-body-col">

                  </div>
              </div>

          </div>
      </v-col> -->
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
      return {
          fixCol: 30,
          fixColBac: 20,
          fix_column: 20,
          rows:6,
          cols: 30
      }
  },
  computed: {
      ...mapGetters("$_yuki160s", {
          resultObj: "getLastTwentyResult"
      }),
      ...mapGetters("$_dashboard", {
          channel: "getChannel",
      }),
      orderBigSmall() {
          return this.getListResults(1);
      },
      orderRange() {
          return this.getListResults(2);
      },
      orderOddEven() {
          return this.getListResults(3);
      },
      orderRedBlue() {
          return this.getListResults(4);
      },
      orderYearResult() {
          return this.getYearResults();
      },
      // getColor(resultName) {
      //     if (resultName === 'BANKER') {
      //         return "error"
      //     } else if (resultName === 'PLAYER') {
      //         return "primary";
      //     } else if (resultName === 'TIE') {
      //         return "success";
      //     } else {
      //         return "default";
      //     }
      // }
  },
  methods: {
    getYearResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (this.resultObj !== null) {
        let resultList = this.resultObj;
        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          if (row <= this.rows) {
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          } else {
            row = 1
            col += 1
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          }
        }
      }
      return newResult;
    },
    getListResults(type) {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (this.resultObj !== null && this.resultObj.length > 0) {
        let resultList = this.resultObj;
        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          if (index == resultList.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if(type == 1){
              if (pre.small_large.name !== item.small_large.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            }else if(type == 2){
              if (pre.range.name !== item.range.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            }else if(type == 3){
              if (pre.odd_even.name !== item.odd_even.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            }else if(type == 4){
              if (pre.blue_red.name !== item.blue_red.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            }
          }
        }
        if (newResult[newResult.length - 1].col > this.cols) {
          this.fixCol = newResult[newResult.length - 1].col
        } else {
          this.fixCol = this.cols
        }
      }
      return newResult;
    },
    findImageOfEachYear(yearId) {
      switch (yearId) {
        case 1:
          return require('../../../../../public/images/animal/Asset 1.png');
        case 2:
          return require('../../../../../public/images/animal/Asset 2.png');
        case 3:
          return require('../../../../../public/images/animal/Asset 3.png');
        case 4:
          return require('../../../../../public/images/animal/Asset 4.png');
        case 5:
          return require('../../../../../public/images/animal/Asset 5.png');
        case 6:
          return require('../../../../../public/images/animal/Asset 6.png');
        case 7:
          return require('../../../../../public/images/animal/Asset 7.png');
        case 8:
          return require('../../../../../public/images/animal/Asset 8.png');
        case 9:
          return require('../../../../../public/images/animal/Asset 9.png');
        case 10:
          return require('../../../../../public/images/animal/Asset 10.png');
        case 11:
          return require('../../../../../public/images/animal/Asset 11.png');
        case 12:
          return require('../../../../../public/images/animal/Asset 12.png');
        default:
        // code block
      }
    },
  },
};
</script>
<style scoped>
.history-result td {
text-align: center;
height: 45px;
width: 45px;
padding: 2px;
background: #f2f6f9;
border: 1px solid #96afde;
vertical-align: middle;
box-shadow: -1px 0 #e4dbc5, 0 1px #e4dbc5, 1px 0 #e4dbc5, 0 -1px #e4dbc5;
-moz-box-shadow: 0 0 2px 1px #fff;
-webkit-box-shadow: 0 0 2px 1px #fff;
-ms-box-shadow: 0 0 2px 1px #fff;
-o-box-shadow: 0 0 2px 1px #fff;
}

.center-padding-0 {
text-align: center;
padding: 0px !important;
}

.result-table {
width: 100%;
margin: 0;
overflow-y: scroll !important;
overflow-x: scroll !important;
}

.trend-table {
border-spacing: 0px !important;
}

.trend-table td {
width: 60px;
height: 60px;
font-size: 10px;
text-align: center;
padding: 0px;
}

.trend-table tr,
td {
border: 1px solid #c5c5c5;
}

/* table tbody{
  height: 156px;
} */

td.result>div {
width: 60px;
}

/* v-tabs{
background: white !important;
} */
.theme--light.v-tabs>.v-tabs-bar {
display: none !important;
}

.v-tabs-bar__content {
display: none !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
background-color: #174e87;
}
.v-tabs:not(.v-tabs--vertical) .v-tab span{
color: white !important;
}

.v-tab--active{
background-color: #0a295a !important;
}

.trend-table tbody tr td{ border: 0 ;}
.trend-table tbody tr{border: 0 ;}
.trend-table tbody tr:nth-child(odd){background-color: #e6f1fd;}
.trend-table tbody tr:nth-child(even){background-color: #ffffff;}


</style>
