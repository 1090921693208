<template>
  <v-row>
    <v-col cols="12" sm="12" class="mx-auto">
      <TabChannelGame
        class="mt-3 mb-1"
        :passSelectedChannel="passSelectedChannel"
        :passChannelStatus="passChannelStatus"
        :passStartIndex="0"
        :passEndIndex="6"
        v-on:sentSetChannel="_switchChannel"
      />
      <VideoLiveForDesktop
        :channel="passChannel"
        :passSelectedChannel="passSelectedChannel"
      />
      <ResultVideoDistribution
        class="mt-0"
        :passSelectedChannel="passSelectedChannel"
        :passChannel="passChannel"
      />
      <TabChannelGame
        class="my-0"
        v-if="userInfo.home_channel && userInfo.home_channel.length >= 7"
        :passSelectedChannel="passSelectedChannel"
        :passChannelStatus="passChannelStatus"
        :passStartIndex="7"
        :passEndIndex="14"
        v-on:sentSetChannel="_switchChannel"
      />
      <TabChannelGame
        v-if="userInfo.home_channel && userInfo.home_channel.length >= 15"
        :passSelectedChannel="passSelectedChannel"
        :passChannelStatus="passChannelStatus"
        :passStartIndex="15"
        :passEndIndex="22"
        v-on:sentSetChannel="_switchChannel"
      />
      <BettingComponent
        :passCurrentBetting="passCurrentBetting"
        :passChannel="passChannel"
        :passGetButton="passGetButton"
        :passBettingTypeMaster="passBettingTypeMaster"
        :passWeight="passWeight"
        v-on:sentSubmitBetting="receiveSubmitBetting"
        :passSelectedChannel="passSelectedChannel"
        :passUserCurrency="passUserCurrency"
      />
      <ReportBetting
        v-if="getIsReport"
        :passSelectedChannel="passSelectedChannel"
        :passLoadingTodayReport="passLoadingTodayReport"
      />
      <TableResult
        :rows="6"
        :cols="35"
        :passSelectedChannel="passSelectedChannel"
        style="margin-top: 2px"
      />
    </v-col>
  </v-row>
</template>
<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import { mapGetters } from "vuex";
import Mixin from "../../../../mixin/index";
import VideoLiveForDesktop from "../videoLiveForDesktop.vue";
import TableResult from "../TableResultMobile";
import BettingComponent from "./BettingComponent.vue";
import ReportBetting from "../ReportBetting.vue";
import TabChannelGame from "../DesktopScreenComponent/TabChannelGame.vue";
import ResultVideoDistribution from "../DesktopScreenComponent/ResultVideoDistribution.vue";
export default {
  components: {
    TabChannelGame,
    ResultVideoDistribution,
    VideoLiveForDesktop,
    TableResult,
    BettingComponent,
    ReportBetting,
  },
  props: [
    "passChannel",
    "passCurrentBetting",
    "passChannelStatus",
    "passResult",
    "passGetStatus",
    "passGetStatusColor",
    "passGetResultStatus",
    "passUserInfo",
    "passWeight",
    "passIsBetting",
    "passBettingTypeMaster",
    "passGetButton",
    "passTodayReport",
    "passLoadingTodayReport",
    "passChannelList",
    "passSelectedChannel",
    "passNewChannelList",
    "passUserCurrency",
  ],
  data() {
    return {
      active_tab: 0,
      channelList: [],
      channelSelected: 1,
      tabs: null,
      bettingMoney: 0,
      loading: false,
      error: false,
      bettingObj: {
        number_id: null,
        amount: null,
      },
      currencyType: "",
      betSelected: null,
      bettingStatus: "",
      isBetsuccess: true,
      isReport: true,
      numberRule: (val) => {
        if (val < 0) return "Please enter a positive number";
        return true;
      },
      items: [{ title: "1" }, { title: "2" }, { title: "3" }, { title: "4" }],
      mixins: [Mixin],
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
    }),
    ...mapGetters("$_dashboard", {
      channelObj: "getChannel",
      // Tiger Dragon
      dragonCard: "getDragonCard",
      tigerCard: "getTigerCard",
      //Baccarat
      playerCard1: "getPlayerCard1",
      playerCard2: "getPlayerCard2",
      playerCard3: "getPlayerCard3",
      bankerCard1: "getBankerCard1",
      bankerCard2: "getBankerCard2",
      bankerCard3: "getBankerCard3",
      bankerPoint: "getBankerPoint",
      playerPoint: "getPlayerPoint",
      getIsReport: "getIsReport",
      //Lotto
      listLotto: "getListLotto",
      // klaklouk
      listKlaklouk: "getListKlaklouk",
      // abong
      listAbong: "getListAbong",
      // yuki
      listYuki: "getListYuki",
      // taisiv
      listTaiSiv: "getListTaiSiv",
    }),
  },
  methods: {
    setIsReport() {
      this.isReport = !this.isReport;
    },

    checkStatus() {
      if (this.passChannel.is_open == null) {
        return "text-warning";
      } else {
        if (this.passChannel.is_open) {
          return "text-success";
        }
        return "text-danger";
      }
    },
    showResultByColor(result) {
      if (result.results[0]) {
        if (result.results[0].name === "1" || result.results[0].name === "4") {
          return "red";
        } else {
          return "black";
        }
      } else {
        return "primary";
      }
    },
    getBettingStatus() {
      if (
        this.passChannel.is_open &&
        this.passUserInfo.enable &&
        this.passChannel.enable
      ) {
        return true;
      }
      return false;
    },
    checkSattus() {
      if (this.passChannel.is_open == null) {
        return "Not Open";
      } else {
        if (this.passChannel.is_open) {
          return "Open";
        }
        return "Close";
      }
    },
    resultStatue() {
      if (this.passChannel.is_open == null && this.passResult.results[0]) {
        return true;
      } else {
        return false;
      }
    },
    resultChannelColor(index) {
      // console.log(`channelStatus: ${JSON.stringify(this.channelStatus)}`)
      if (this.passChannelStatus) {
        for (var i = 0; i < this.passChannelStatus.length; i++) {
          if (this.passChannelStatus[i].channel_type == index) {
            if (this.passChannelStatus[i].status == null) {
              return "orange";
            } else {
              if (this.passChannelStatus[i].status) {
                return "green";
              }
              return "red";
            }
          }
        }
      }
    },
    payoutFormat(num) {
      return num;
    },
    receiveSubmitBetting(recieveBettingData) {
      this.$emit("sentSubmitBetting", recieveBettingData);
    },
    // receiveRefreshTodayReport() {
    //   this.$emit("sentRefreshTodayReport");
    // },
    // receiveRefreshCurrentReport() {
    //   this.$emit("sentRefreshCurrentReport");
    // },
    _switchChannel(selectedChannel) {
      this.$emit("sentSetChannel", selectedChannel);
    },
  },
};
</script>
<style>
.video-section {
  position: relative;
  overflow: hidden;
  background-color: #003366;
}

.v-btn:not(.v-btn--round).v-size--default {
  min-width: 30px !important;
}
.img-channel-logo {
  width: 40px;
  height: 40px;
  margin-top: -3px;
}
</style>