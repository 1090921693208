<template>
    <div class="mx-auto row w-100 justify-center pb-2" >
        <div  class="mr-3">
            <v-btn  @click="_clearMoney" class="btn btn-secondary mx-0 row mt-0 btn-clear-money"  :disabled="loading"
                style="height:40px!important">
                <v-icon style="margin-right:4px" color="white" size="17px">mdi-close-circle-outline</v-icon>
                <span style="margin-top:-2px;">
                    {{ $t('title.cancel') }}
                </span>
            </v-btn>
        </div>
        <div class="ml-3">
            <v-btn @click="_submitBetting" class="btn btn-secondary row mx-0 mt-0 btn-submit-money" :disabled="!passCheckAllowSubmit || loading" 
                style="height:40px!important">
                <v-icon v-if="!loading" style="margin-right:4px" color="white" size="17px">mdi-check-circle-outline</v-icon>
                <v-icon v-if="loading">
                    mdi-refresh mdi-spin
                </v-icon>
                <span style="margin-top:-2px;">
                    {{ $t('title.confirm') }}
                </span>
            </v-btn>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    
    props: [
        'passCheckAllowSubmit',
    ],
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters("$_dashboard", {
        loading: "getLoading",
        }),
    },

    methods: {
        _submitBetting() {
            this.$emit("handleSubmitBtn");
        },
        _clearMoney() {
            this.$emit("handleClearBtn");
        },
    }
}
</script>
<style scoped>    
.btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    width: 150px;
    text-align: center;
    justify-content: center;
}
.btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        height:30px!important;
        justify-content: center;
    }
.btn-clear-money:hover{
    cursor: pointer;
}
.btn-submit-money:hover{
    cursor: pointer;
}
</style>