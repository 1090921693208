import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    lastResults:[],
    currentBetting:[],
    previousBetting:[],
    currentGame:[],
    rangeType:[],
    headTailType:[],
    lastOneResult:[],
    lastTwentyResult:[],
    is_Betting : false,
    buttonMoney:[],
    lastFiveResults:[],
    typeMaster: null,
    firstSixBtn: [],
    sixToTwelve: [],
    rangeSmall: [],
    rangeBig: [],
    smallBig:[],
    oddPair:[],
    blueRed:[],
    totalInvoice:0,
    limitYukiBetting:[],
    selectBtn:null
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
